// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import api from "../../api/axiosInstance";
import { useHistory } from "react-router-dom";

const initialState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
  user: JSON.parse(localStorage.getItem("user")) || null,
};

export const updateInterestId = (interestId) => (dispatch) => {
  dispatch(authSlice.actions.updateInterestId(interestId));
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("user", JSON.stringify(action.payload));
      toast.success("Logged in successfully");
    },
    signupSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("user", JSON.stringify(action.payload));
      toast.success("Signed up successfully");
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("access_token");
      localStorage.setItem("isAuthenticated", "false");
      localStorage.removeItem("user");
      localStorage.removeItem("interestId");
      localStorage.removeItem("applicationId");
      localStorage.removeItem("validatedFormikValues");
    },
  },
});

export const { loginSuccess, signupSuccess, logout } = authSlice.actions;

export const login = (email, password, history) => async (dispatch) => {
  try {
    const response = await api.post("/auth/login", { email, password });
    const { access_token, user } = response.data;

    localStorage.setItem("access_token", access_token);
    dispatch(loginSuccess({ access_token, user }));

    history.push("/grants");
  } catch (error) {
    console.log(error);

    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An unexpected error occurred");
    }

    return { success: false };
  }
};

export const signup = (userData, history) => async (dispatch) => {
  try {
    await api.post("/auth/SignUp", userData);

    toast.success("Signed up successfully. Please login to continue.");
    history.push("/login");

    return { success: true };
  } catch (error) {
    console.log(error);

    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message[0]);
    } else {
      toast.error("An unexpected error occurred");
    }

    return { success: false };
  }
};

export default authSlice.reducer;
