import React, { useEffect, useRef } from "react";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import OpenCard from "../../components/cards/openCard/openCard";
import "./grants.scss";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import ProgressCard from "../../components/cards/progressCard/ProgressCard";
import NotApprovedCard from "../../components/cards/progressCard/NotApprovedCard";
import ApprovedCard from "../../components/cards/progressCard/ApprovedCard";
import FullApplicationProgressCard from "../../components/cards/progressCard/FullApplicationProgressCard";
import PhaseSecondCard from "../../components/cards/progressCard/PhaseSecondCard";
import { BeatLoader } from "react-spinners";
import ApplicationReview from "../../components/cards/progressCard/ApplicationReview";
import Admin from "../../pages/admin/Admin";
import Accordion from "../../components/accordion/Accordion";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInterest } from "../../store/features/userApplicationsSlice";
import ReactToPrint from "react-to-print";
import ApplicationPdf from "../grantsForSme/ApplicationPdf";
import ApplicationPdfSecond from "../grantsForSme/ApplicationPdfSecond";
import { useTranslation } from "react-i18next";

export default function Grants() {
  const { t, i18n } = useTranslation();
  const breadcrumbItems = [
    { label: "Grants Managment Platform", url: "/" },
    { label: "Grants" },
  ];
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const token = user?.access_token;
  const { data, loading } = useSelector((state) => state.userInterest);
  const eoiRef = useRef();
  const fullGrantRef = useRef();
  const [eoiPrintContentVisibility, setEoiPrintContentVisibility] =
    React.useState("none");
  const [fullGrantPrintContentVisibility, setFullGrantPrintContentVisibility] =
    React.useState("none");

  useEffect(() => {
    if (user?.user?.id && token) {
      dispatch(fetchUserInterest({ userId: user?.user?.id, token }));
    }
  }, [dispatch]);

  const isAlbanian = i18n.language === "al";

  const link = isAlbanian
    ? "https://kcdf.org/wp-content/uploads/2023/04/001_IEE_APS-2023-01_FAQ_Albanian.pdf"
    : "https://kcdf.org/wp-content/uploads/2023/04/001_IEE_APS-2023-01_FAQ_English.pdf";

  return (
    <>
      <Navbar />
      <div className="top-container">
        {user?.user?.isAdmin === true ? (
          <>
            <Admin />
          </>
        ) : (
          <>
            <Breadcrumbs items={breadcrumbItems} />
            <div className="container">
              {loading ? (
                <div className="loader">
                  <BeatLoader color="red" loading={loading} />
                </div>
              ) : (
                <>
                  <div className="container">
                    {data ? (
                      ""
                    ) : (
                      <h2>
                        {t("welcome")}, {user?.user?.firstName}{" "}
                        {user?.user?.lastName}
                      </h2>
                    )}
                  </div>
                  {!data && <OpenCard data={data} />}
                  {data?.id && data?.isVisit === null && <ProgressCard />}
                  {data?.visit?.isVisitApproved === null && (
                    <ApplicationReview />
                  )}
                  {data?.isVisit === 1 &&
                    data?.application === null &&
                    data?.visit?.isVisitApproved === 1 && <PhaseSecondCard />}
                  {data?.application?.isApproved === null && (
                    <FullApplicationProgressCard />
                  )}
                  {data?.application?.isApproved === true && <ApprovedCard />}
                  <NotApprovedCard data={data} />
                </>
              )}
            </div>


            {data?.isVisit === 1 &&
            data?.application === null &&
            data?.visit?.isVisitApproved === 1 ? (
              <div className="container">
                <div
                  className="eligibility-container"
                  style={{ paddingTop: "30px", paddingBottom: "20px" }}
                >
                  <div className="eligibility-title">
                    <h1>
                      {t("fullGrant")}
                      <br></br>
                      {t("application")} <br></br>
                      {t("process")}
                    </h1>
                  </div>
                  <div className="eligibility-title-right">
                    <h1> </h1>
                    <p style={{ textAlign: "justify" }}>{t("fgaResponse")}</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {data?.isVisit === 1 &&
              data?.application === null &&
              data?.visit?.isVisitApproved === 1 && (
                <div className="container">
                  <div
                    className="eligibility-container"
                    style={{ paddingTop: "0px" }}
                  >
                    <div className="eligibility-title">
                      <h1>
                        <br></br>
                      </h1>
                    </div>
                    <div className="eligibility-title-right">
                      <Link to="grants-for-sme-second">
                        <h3
                          className="eligibility-title-instructions"
                          style={{
                            backgroundColor: "rgb(195, 32, 51)",
                            borderRadius: "15px",
                            padding: "20px",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {t("applyHere")}
                        </h3>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

            {data?.id && data?.isVisit === null || data?.visit?.isVisitApproved === null && (
              <div className="container">
                <div
                  className="eligibility-container"
                  style={{ paddingTop: "20px" }}
                >
                  <div className="eligibility-title">
                    <h1>{t("yourEOIApplication")}</h1>
                  </div>
                  <div
                    className="eligibility-title-right"
                    style={{ cursor: "pointer" }}
                  >
                    <ReactToPrint
                      trigger={() => (
                        <h3
                          className="eligibility-title-instructions"
                          style={{
                            backgroundColor: "rgb(195, 32, 51)",
                            borderRadius: "15px",
                            padding: "20px",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {t("downloadApplication")}
                        </h3>
                      )}
                      content={() => eoiRef.current}
                      onBeforeGetContent={() => {
                        setEoiPrintContentVisibility("block");
                        return new Promise((resolve) => setTimeout(resolve, 0));
                      }}
                      onBeforePrint={() => setEoiPrintContentVisibility("none")}
                      onAfterPrint={() => setEoiPrintContentVisibility("none")}
                    />
                  </div>
                </div>
              </div>
            )}

            <div ref={eoiRef} style={{ display: eoiPrintContentVisibility }}>
              <ApplicationPdf reff={eoiRef} application={data} />
            </div>

            {data?.application?.isApproved === null && (
              <div className="container">
                <div
                  className="eligibility-container"
                  style={{ paddingTop: "20px" }}
                >
                  <div className="eligibility-title">
                    <h1>
                      {t("application")}
                      <br />
                      {t("process")}
                    </h1>
                  </div>
                  <div className="eligibility-title-right">
                    <ReactToPrint
                      trigger={() => (
                        <h3
                          className="eligibility-title-instructions"
                          style={{
                            backgroundColor: "rgb(195, 32, 51)",
                            borderRadius: "15px",
                            padding: "20px",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {t("downloadApplication")}
                        </h3>
                      )}
                      content={() => fullGrantRef.current}
                      onBeforeGetContent={() => {
                        setFullGrantPrintContentVisibility("block");
                        return new Promise((resolve) => setTimeout(resolve, 0));
                      }}
                      onBeforePrint={() =>
                        setFullGrantPrintContentVisibility("none")
                      }
                      onAfterPrint={() =>
                        setFullGrantPrintContentVisibility("none")
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              ref={fullGrantRef}
              style={{ display: fullGrantPrintContentVisibility }}
            >
              <ApplicationPdfSecond reff={fullGrantRef} application={data} />
            </div>

            {!data ? (
              <div className="container">
                <div
                  className="eligibility-container"
                  style={{ paddingTop: "20px" }}
                >
                  <div className="eligibility-title">
                    <h1>{t("annualProgramStatement")}</h1>
                  </div>
                  <div className="eligibility-title-right">
                    <h1> </h1>
                    <p style={{ textAlign: "justify" }}>
                      {" "}
                      {t("annualFirstParagraph")}
                      <br></br>
                      <br></br>
                      {t("annualSecondParagraph")} <br></br>
                      <br></br>
                      {t("annualThirdParagraph")}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {data?.isVisit != 0 ||
              (data?.application?.isApproved != false && (
                <div className="container">
                  <div
                    className="eligibility-container"
                    style={{ paddingTop: "0px" }}
                  >
                    <div className="eligibility-title">
                      <h1>
                        {t("deadline")}
                        <br></br> {t("application")}
                      </h1>
                    </div>
                    <div className="eligibility-title-right">
                      <h3
                        className="eligibility-title-instructions"
                        style={{
                          backgroundColor: "rgb(195, 32, 51)",
                          borderRadius: "15px",
                          padding: "20px",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        {data?.application?.isApproved === null
                          ? "You have two weeks notice"
                          : "Applications are open from March 31, 2023, to September 8, 2023."}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}

            <div className="container">
              <div className="eligibility-container" style={{ padding: "0" }}>
                <div className="eligibility-title">
                  <h1>{t("faq")}</h1>
                </div>
                <div className="eligibility-title-right">
                  <div className="eligibility-paragraph"></div>
                  <Accordion
                    title={t("maxAwardQuestion")}
                    content={t("maxAwardAnswer")}
                  />
                  <Accordion
                    title={t("whoCanApplyQuestion")}
                    content={t("whoCanApplyAnswer")}
                  />
                  <Accordion
                    title={t("bothWomenMinorityOwnedQuestion")}
                    content={t("bothWomenMinorityOwnedAnswer")}
                  />
                  <Accordion
                    title={t("mandatoryCostSharingQuestion")}
                    content={t("mandatoryCostSharingAnswer")}
                  />
                </div>
              </div>
            </div>

            <div className="container">
              <div
                className="eligibility-container"
                style={{ paddingTop: "10px" }}
              >
                <div className="eligibility-title"></div>
                <div
                  className="eligibility-title-right"
                  style={{ height: "70px", marginBottom: "20px" }}
                >
                  <a href={link}>
                    <h3
                      className="eligibility-title-instructions"
                      style={{
                        backgroundColor: "rgb(195, 32, 51)",
                        borderRadius: "15px",
                        padding: "20px",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {t("readMoreFAQ")}
                    </h3>
                  </a>
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
}
