import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Accordion from "../../components/accordion/Accordion";
import Footer from "../../components/footer/Footer";

export default function Resources() {
  const breadcrumbItems = [
    { label: "Grants Managment Platform", url: "/" },
    { label: "Resources" },
  ];
  return (
    <>
      <Navbar />
      <div className="top-container">
        <Breadcrumbs items={breadcrumbItems} />
        <div className="resources-container">
          <div className="container">
            <h3>Resources</h3>
            {""}

            <div className="resources-application-container">
              <div className="award-application-document-container">
                <div className="award-application-document">
                  <img src="/assets/images/icons/folderNotch.svg" alt="" />
                  <p className="resources-title">Writing Templates</p>
                  <button className="download-btn">Download</button>
                </div>
              </div>
              <div className="award-application-document-container">
                <div className="award-application-document">
                  <img src="/assets/images/icons/folderNotch.svg" alt="" />
                  <p className="resources-title">Writing Templates</p>
                  <button className="download-btn">Download</button>
                </div>
              </div>
              <div className="award-application-document-container">
                <div className="award-application-document">
                  <img src="/assets/images/icons/folderNotch.svg" alt="" />
                  <p className="resources-title">Writing Templates</p>
                  <button className="download-btn">Download</button>
                </div>
              </div>
            </div>

            {""}

            <div className="container">
              <div className="eligibility-container">
                <div className="eligibility-title">
                  <h1>FAQs</h1>
                </div>
                <div className="eligibility-title-right">
                  <div className="eligibility-paragraph"></div>
                  <Accordion
                    title="What is IEE"
                    content="Rhoncus sed facilisi sed amet. Tristique commodo sodales."
                  />
                  <Accordion
                    title="Eligibility criteria"
                    content="This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion."
                  />
                  <Accordion
                    title="Instructions and guidelines for applying"
                    content="This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion."
                  />
                  <Accordion
                    title="Notification of award"
                    content="This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
