import React, { useState, useEffect, useRef } from "react";
import "./adminList.scss";
import { useSelector } from "react-redux";
import Modal from "./modal/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplicationPdf from "../../../pages/grantsForSme/ApplicationPdf";
import { savePDF } from "@progress/kendo-react-pdf";
import AdminNavbar from "../adminNavbar/AdminNavbar";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default function AdminListPhaseOne() {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState("");
  const componentRef = useRef();
  const handleDownloadPdf = (application) => {
    setSelectedApplication(application);
  };

  useEffect(() => {
    if (selectedApplication) {
      savePDF(componentRef.current, {
        title: "Pdf Application",
        fileName: `PDF APPLICATION ${selectedApplication.legal_Representative_fullName} `,
        paperSize: "A4",
      });
    }
  }, [selectedApplication]);

  useEffect(() => {
    const token = user?.access_token;

    fetch("https://apigrant.bbros.al/api/interest-expression/findAllInterst", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setApplications(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("API request error:", error);
        setIsLoading(false);
      });
  }, []);

  const handleApprove = (applicationId, isVisit) => {
    const token = user?.access_token;
    const updatedIsApprove = isVisit ? 1 : 0;
    fetch(
      `https://apigrant.bbros.al/api/interest-expression/approveApplication/${applicationId}/${updatedIsApprove}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isVisit: updatedIsApprove, content }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setApplications((prevApplications) =>
          prevApplications.map((app) =>
            app.id === applicationId
              ? { ...app, isVisit: updatedIsApprove }
              : app
          )
        );
        toast.success("Application updated successfully!");
      })
      .catch((error) => {
        console.log("API request error:", error);
        toast.error(error.message);
      });
  };

  const handleAction = (action, application) => {
    setSelectedApplication(application);

    if (action === "approve") {
      setIsApproveModalOpen(true);
    } else if (action === "reject") {
      setIsRejectModalOpen(true);
    } else if (action === "view") {
      handleDownloadPdf(application);
    }
  };

  const closeModal = () => {
    setSelectedApplication(null);
    setIsApproveModalOpen(false);
    setIsRejectModalOpen(false);
  };

console.log("adsd" , applications)
  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("EOI");

    const customHeaders = [
      "Name and Surname",
      "Nationality",
      "Business Name",
      "Municipality",
      "Type of business",
      "Email",
      "Phone",
      "No of Employes",
      "Requested sum",
      "Cost share",
      "Total",
      "Comments",
      "Status",
    ];

    const headerRow = worksheet.addRow(customHeaders);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D0D5DD" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    const dataColumn = worksheet.getColumn(7);
    dataColumn.width = 25;
    const emriColumn = worksheet.getColumn(2);
    emriColumn.width = 20;
    applications.forEach(
      ({
        legal_Representative_fullName,
        ethnicAffiliation,
        businessName,
        municipality,
        businessDescription,
        email,
        phoneNumber,
        employeeNumber,
        grant_sum,
        total_cost,
        status,
      }) => {
        worksheet.addRow([
          legal_Representative_fullName,
          ethnicAffiliation,
          businessName,
          municipality,
          businessDescription,
          email,
          phoneNumber,
          employeeNumber,
          grant_sum,
          total_cost,
          status,
        ]);
      }
    );
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "exportTable.xlsx");
  };

  return (
    <>
      <div className="applicant-list-container">
        <AdminNavbar />
        <div className="main-container">
          <div className="top-container">
            <div>
              <Link to="/admin">
                <div className="applicant-list-arrow">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                  <p>Kthehu mrapa</p>
                </div>
              </Link>
              <h1>Aplikuesit ne fazen e Shprehjes se Interesit</h1>
            </div>
            <div className="export-list">
              <button onClick={handleExportToExcel}>Export Excel</button>
            </div>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <TableComponent
                applications={applications}
                handleAction={handleAction}
              />
            )}
          </div>
        </div>
        {isApproveModalOpen && (
          <ApproveModal
            isOpen={isApproveModalOpen}
            onClose={closeModal}
            onConfirm={() => handleApprove(selectedApplication.id, 1)}
            content={content}
            setContent={setContent}
          />
        )}
        {isRejectModalOpen && (
          <RejectModal
            isOpen={isRejectModalOpen}
            onClose={closeModal}
            onReject={() => handleApprove(selectedApplication.id, 0)}
            content={content}
            setContent={setContent}
          />
        )}
        <div style={{ height: 0, overflow: "hidden" }}>
          {selectedApplication && (
            <ApplicationPdf
              ref={componentRef}
              application={selectedApplication}
              interestId={selectedApplication?.interestId}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

const LoadingComponent = () => (
  <div className="loader">
    <BeatLoader color="red" loading={true} />
  </div>
);

const TableComponent = ({ applications, handleAction }) => (
  <table className="applicant-table">
    <thead>
      <tr>
        <th>#</th>
        <th>Name and Surname</th>
        <th>Business Name</th>
        <th>E-mail</th>
        <th>Phone Number</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {applications.map((app) => (
        <TableRowComponent
          key={app.id}
          application={app}
          handleAction={handleAction}
        />
      ))}
    </tbody>
  </table>
);

const TableRowComponent = ({ application, handleAction }) => (
  <tr>
    <td>{`#${application.id}`}</td>
    <td>{application.legal_Representative_fullName}</td>
    <td>{application.businessName}</td>
    <td>{application.email}</td>
    <td>{application.phoneNumber}</td>
    <td>
      {application.isVisit === 1 ? (
        <p style={{ color: "green" }}>Approved</p>
      ) : application.isVisit === 0 ? (
        <p style={{ color: "red" }}>Rejected</p>
      ) : (
        <p style={{ color: "orange" }}>In review</p>
      )}
    </td>
    <td className="applicant-button">
      <select onChange={(e) => handleAction(e.target.value, application)}>
        <option value="none">Select an action</option>
        <option value="approve">Approve for Field Visit</option>
        <option value="reject">Reject</option>
      </select>
      <button
        onClick={() => handleAction("view", application)}
        style={{ padding: "5px", backgroundColor: "#8888884a", color: "white" }}
      >
        View Application
      </button>
    </td>
  </tr>
);

const ApproveModal = ({ isOpen, onClose, onConfirm, content, setContent }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    actionType="approve"
    content={content}
    setContent={setContent}
  />
);

const RejectModal = ({ isOpen, onClose, onReject, content, setContent }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    onReject={onReject}
    actionType="reject"
    content={content}
    setContent={setContent}
  />
);
