import React from "react";
import "./footer.scss";
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  
  return (
    <div className="footer-container">
      <div className="container">
        <div className="footer-logos">
          <div className="footer-logos-sponsors">
            <p
              style={{
                fontWeight: "300",
                color: "#6E6E73",
                fontFamily: "Inter",
                fontSize: "20px",
              }}
            >
              {t('footer.implementedBy')}
            </p>
            <img
              style={{ width: "120px" }}
              src="/assets/images/cdf.png"
              alt=""
            ></img>
          </div>
          <div className="footer-logos-sponsors">
            <p
              style={{
                fontWeight: "300",
                color: "#6E6E73",
                fontFamily: "Inter",
                fontSize: "20px",
              }}
            >
              {t('footer.fundedBy')}
            </p>
            <img
              style={{ width: "180px" }}
              src="/assets/images/usaid.png"
              alt=""
            ></img>
          </div>
        </div>
      </div>
      <div className="footer-menu">
        <div className="container">
          <div className="footer-menu-container">
            <div className="footer-menu-lists">
              <ul>
                <a href="https://toolkit.bbroshost.com/">
                  <li>{t('footer.home')}</li>
                </a>
                <a href="https://toolkit.bbroshost.com/about-ieea/">
                  <li>{t('footer.aboutIEEA')}</li>
                </a>
                <a href="https://toolkit.bbroshost.com/business/">
                  <li>{t('footer.toolkit')}</li>
                </a>
              </ul>
              <ul>
                <a href="https://toolkit.bbroshost.com/news/">
                  <li>{t('footer.news')}</li>
                </a>
                <a href="https://toolkit.bbroshost.com/success-stories/">
                  <li>{t('footer.successStories')}</li>
                </a>
                <a href="https://toolkit.bbroshost.com/contact-us/">
                  <li>{t('footer.contactUs')}</li>
                </a>
              </ul>
            </div>
            <div className="footer-menu-subscribe">
              <div className="footer-menu-subscribe-joinus">
                <p>{t('footer.joinUs')}</p>
                <input placeholder={t('footer.emailAddressPlaceholder')}></input>
              </div>
              <div className="footer-menu-social">
                <div className="footer-menu-social-logo">
                  <a href="https://www.facebook.com/CommunityDevelopmentFund/">
                  <img
                    src="/assets/images/icons/facebook.svg"
                    alt="facebook"
                  ></img>
                  </a>
                  <a href="https://www.instagram.com/communitydevelopmentfund/">
                  <img
                    src="/assets/images/icons/instagram.svg"
                    alt="instagram"
                  ></img>
                  </a>
                  <a href="https://www.linkedin.com/company/community-development-fund/mycompany/">
                  <img
                    src="/assets/images/icons/linkedin.svg"
                    alt="linkedin"
                  ></img>
                  </a>
                </div>
                <button>{t('footer.subscribeButton')}</button>
              </div>
            </div>
            <div className="footer-menu-contact">
              <p>{t('footer.address')}</p>
              <br></br>
              <p className="underline-paragraph">{t('footer.mail')}: <span>ieea@kcdf.org</span> </p>
              <p className="underline-paragraph">{t('footer.tel')}: <span> +383 (0) 38 249 677/678 </span></p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-second-container">
        <div className="container">
          <p>
            {t('footer.copyright')}
          </p>
        </div>
      </div>
    </div>
  );
}
