import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../components/button/Button";
import { useDispatch } from "react-redux";
import { signup } from "../../store/features/authSlice";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "./signup.scss";
import { useTranslation } from "react-i18next";

export default function SignUp() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (values) => {
    const { success } = await dispatch(signup(values, history));
    if (success) {
      history.push("/login");
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        userType: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Password must be at least 8 characters long")
          .required("Password is required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <>
          <div className="signup-container">
            <div className="login-header"></div>
            <div className="login-form-container">
              <div className="login-form">
                <h3>{t("signup.signUp")}</h3>
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className={`input-wrapper ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    <Field
                      type="text"
                      name="firstName"
                      className={
                        formik.touched.firstName && formik.errors.firstName
                          ? "error"
                          : ""
                      }
                    />
                    <label
                      htmlFor="firstName"
                      className={`placeholder-label ${
                        formik.values.firstName ? "active" : ""
                      }`}
                    >
                      {t("signup.name")}
                    </label>
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div
                    className={`input-wrapper ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    <Field
                      type="text"
                      name="lastName"
                      className={
                        formik.touched.lastName && formik.errors.lastName
                          ? "error"
                          : ""
                      }
                    />
                    <label
                      htmlFor="lastName"
                      className={`placeholder-label ${
                        formik.values.lastName ? "active" : ""
                      }`}
                    >
                      {t("signup.surname")}
                    </label>
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div
                    className={`input-wrapper ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    <Field
                      type="number"
                      name="phoneNumber"
                      className={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? "error"
                          : ""
                      }
                    />
                    <label
                      htmlFor="phoneNumber"
                      className={`placeholder-label ${
                        formik.values.phoneNumber ? "active" : ""
                      }`}
                    >
                      {t("signup.phoneNumber")}
                    </label>
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div
                    className={`input-wrapper ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    <Field
                      type="email"
                      name="email"
                      className={
                        formik.touched.email && formik.errors.email
                          ? "error"
                          : ""
                      }
                    />
                    <label
                      htmlFor="email"
                      className={`placeholder-label ${
                        formik.values.email ? "active" : ""
                      }`}
                    >
                      {t("signup.email")}
                    </label>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div
                    className={`input-wrapper ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className={
                        formik.touched.password && formik.errors.password
                          ? "error"
                          : ""
                      }
                    />
                    <span
                      className="password-toggle"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className="password-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="password-icon"
                        />
                      )}
                    </span>
                    <label
                      htmlFor="password"
                      className={`placeholder-label ${
                        formik.values.password ? "active" : ""
                      }`}
                    >
                      {t("signup.password")}
                    </label>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div
                    className={`input-wrapper ${
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                        ? "error"
                        : ""
                    }`}
                  >
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      className={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                          ? "error"
                          : ""
                      }
                    />
                    <span
                      className="password-toggle"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className="password-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="password-icon"
                        />
                      )}
                    </span>
                    <label
                      htmlFor="confirmPassword"
                      className={`placeholder-label ${
                        formik.values.confirmPassword ? "active" : ""
                      }`}
                    >
                      {t("signup.confirmPassword")}
                    </label>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>

                  <Button
                    type="submit"
                    title={formik.isSubmitting ? "Loading..." : "Sign Up"}
                    disabled={formik.isSubmitting}
                  />
                </form>
                <a href="/login">
                  <p>
                    {t("signup.alreadyMember")} <span>{t("signup.logIn")}</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
