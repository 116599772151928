import "./App.scss";
import Roots from "./routes/Roots";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <main>
      <Roots />
      <ToastContainer position="bottom-right" />
    </main>
  );
}

export default App;
