import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const DynamicTable = () => {
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState([
    {
      fundingSourceName: "",
      contactPerson: "",
      grantProjectName: "",
      brief_Project_Description: "",
      project_Implementation_Period: "",
    },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  const addRowDynamic = () => {
    setRows([
      ...rows,
      {
        fundingSourceName: "",
        contactPerson: "",
        grantProjectName: "",
        brief_Project_Description: "",
        project_Implementation_Period: "",
      },
    ]);
  };

  const removeRowDynamic = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  return (
    <div className="container">
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>{t("dynamicTable.fundingSourceName")}</th>
              <th>{t("dynamicTable.contactPerson")}</th>
              <th>{t("dynamicTable.grantProjectName")}</th>
              <th>{t("dynamicTable.briefProjectDescription")}</th>
              <th>{t("dynamicTable.projectImplementationPeriod")}</th>
              <th>{t("dynamicTable.action")}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    name="fundingSourceName"
                    value={row.fundingSourceName}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </td>
                <td>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    name="contactPerson"
                    value={row.contactPerson}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </td>
                <td>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    name="grantProjectName"
                    value={row.grantProjectName}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </td>
                <td>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    name="brief_Project_Description"
                    value={row.brief_Project_Description}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </td>
                <td>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    name="project_Implementation_Period"
                    value={row.project_Implementation_Period}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </td>
                <td>
                  <button onClick={() => removeRowDynamic(index)}>
                    {t("dynamicTable.remove")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={addRowDynamic} type="button" className="addNewRow">
          {t("dynamicTable.addRow")}
        </button>
      </div>
    </div>
  );
};

export default DynamicTable;
