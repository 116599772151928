import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import createInterestReducer from "./features/createInterest";
import userInterestReducer from "./features/userApplicationsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    createInterest: createInterestReducer,
    userInterest: userInterestReducer,
  },
});

export default store;
