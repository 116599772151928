import React from "react";
import "./progressCard.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function NotApprovedCard({ data }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const firstName = user?.user?.firstName;

  let messageKey;
  let containerHeight;

  if (data?.isVisit === 0) {
    messageKey = "notApprovedCard.messages.eoiRejected";
    containerHeight = "210px";
  } else if (
    data?.isVisit === 1 &&
    data?.application === null &&
    data?.visit?.isVisitApproved === 0
  ) {
    messageKey = "notApprovedCard.messages.fieldVisitRejected";
    containerHeight = "240px";
  } else if (data?.application?.isApproved === false) {
    messageKey = "notApprovedCard.messages.fullApplicationRejected";
    containerHeight = "260px";
  } else {
    return null;
  }

  return (
    <div
      className="progress-card-container"
      style={{ height: containerHeight, marginBottom: "10px" }}
    >
      <div className="progress-card-info">
        <div className="progress-card-button">
          <div className="progress-card-button-first">
            <button className="button-card-open">
              {t("notApprovedCard.rejectedButton")}
            </button>
          </div>
        </div>
        {t("notApprovedCard.dear")} {firstName},
        <h3>
          {t(messageKey)}
          <br></br>
          <br></br>
          {t("notApprovedCard.messages.secondResponse")}
        </h3>
        <p className="progress-card-details-paragraph">
          {t("notApprovedCard.progressBarDescription")}
        </p>
        <div className="progress-bar-container">
          <div className="progress-bar-background">
            <div
              className="progress-bar"
              style={{ width: "100%", backgroundColor: "rgb(195, 32, 51)" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
