import React from "react";
import "./table.scss";
import { useTranslation } from "react-i18next";

const TableForm = ({ rows, handleInputChange, handleAddRow, handleRemoveRow, handleSubmit }) => {
  const { t, i18n } = useTranslation();
  const calculateGrandTotal = () => {
    let grandTotal = 0;
    rows.forEach((row) => {
      const quantity = parseInt(row.quantity);
      const pricePerUnit = parseFloat(row.price_per_unit);
      const totalPrice = quantity * pricePerUnit;
      grandTotal += totalPrice;
    });
   
    return isNaN(grandTotal) ? 0 : grandTotal.toFixed(2);
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>{t('tableForm.devicesTools')}</th>
            <th>{t('tableForm.quantity')}</th>
            <th>{t('tableForm.pricePerUnit')}</th>
            <th>{t('tableForm.action')}</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  value={row.devices_Tools}
                  onChange={(e) => handleInputChange(index, "devices_Tools", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.quantity}
                  onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.price_per_unit}
                  onChange={(e) => handleInputChange(index, "price_per_unit", e.target.value)}
                />
              </td>
              <td>
                <button type="button" onClick={() => handleRemoveRow(index)}>
                  {t('tableForm.remove')}
                </button>
              </td>
            </tr>
          ))}
          <tr className="total-row">
            <td className="total-label" colSpan="3">
            {t('tableForm.total')}
            </td>
            <td className="total-price">{calculateGrandTotal()}</td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <button type="button" className="addNewRow" onClick={handleAddRow}>
      {t('tableForm.addNewRow')}
      </button>
    </div>
  );
};

export default TableForm;
