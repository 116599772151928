import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../components/button/Button";
import "./forgotPassword.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const handleSubmit = async (values) => {
    try {
      const response = await fetch(
        "https://apigrant.bbros.al/api/password-resets/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      toast.success("Email sent successfully!");
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <div className="forgot-password-container">
          <div className="forgot-password-form-container">
            <div className="forgot-password-title">
              <h3>{t('forgotPasswordPage.forgotPassword')}</h3>
            </div>
            <div className="forgot-password-form">
              <form onSubmit={formik.handleSubmit}>
                <div
                  className={`input-wrapper ${
                    formik.touched.email && formik.errors.email ? "error" : ""
                  }`}
                >
                  <Field
                    type="email"
                    name="email"
                    className={
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }
                  />
                  <label
                    htmlFor="email"
                    className={`placeholder-label ${
                      formik.values.email ? "active" : ""
                    }`}
                  >
                    {t('forgotPasswordPage.email')}
                  </label>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>

                <Button
                  type="submit"
                  title={formik.isSubmitting ? "Loading..." : "Perfundo"}
                  disabled={formik.isSubmitting}
                />
              </form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
