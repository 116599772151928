import React, { useState, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./applicationPdf.scss";
import { BeatLoader } from "react-spinners";
import { Height } from "@mui/icons-material";

const ApplicationPdf = forwardRef((props, reff) => {
  const { application } = props;
  const [responseData, setResponseData] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const token = user.access_token;
  const interestId = application?.id;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://apigrant.bbros.al/api/interest-expression/findInterestById/${interestId}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setResponseData(response.data);
        setLoading(false);
      } catch (error) {}
    };

    fetchData();
  }, [application]);

  if (!responseData) {
    return <BeatLoader color="red" loading={loading} />;
  }

  const {
    acronym,
    activity,
    businessAddress,
    businessDescription,
    businessLegalStatus,
    businessName,
    businessNumber,
    businessOwners,
    customers,
    devices_shop_place,
    email,
    employeeNumber,
    ethnicAffiliation,
    femaleEmployees,
    future_devices,
    future_employees,
    grant_help,
    grant_need,
    grant_sum,
    legal_Representative_fullName,
    minorityMembers,
    permanentWorkers,
    personal_contribute,
    persons_with_disabilities,
    phoneNumber,
    products_Services,
    recived_help,
    registrationDate,
    sales_data,
    seasonalWorkers,
    seasonal_or_ongoing,
    specific_result,
    taxNumber,
    total_cost,
    training_need,
    youngEmployees,
    createdAt,
  } = responseData;

  const dateString = { createdAt };

  return (
    <div ref={reff}>
      <div className="pdf-container">
        <div className="pdf-logos">
          <img
            style={{ width: "80px", height: "50px" }}
            src={require("../../images/cdf.png")}
            alt="cdf"
          ></img>
          <img
            style={{ width: "100px", height: "50px" }}
            src={require("../../images/usaid.png")}
            alt="usaid"
          ></img>
        </div>
        <h3>Expression of Interest (EOI)/Concept Note </h3>
        <div className="pdf-first-paragraph">
          <p>
            Dear Applicant, The Grant Expression of Interest form, it is
            exclusively for Women and Minority-owned businesses with documented
            proof of operation. Your business must meet the following criteria
            to be eligible: owned and operated by women or minorities, operating
            for at least one year, able to provide documented proof, and
            demonstrate a need for funding. The form will help us assess your
            eligibility and funding needs. Please provide accurate and complete
            information! If you have any questions or need assistance please
            contact us in the following e-mail address: grants@kcdf.org. Good
            Luck!
          </p>
        </div>
        <div className="pdf-forms">
          <p>
            Name and Surname of Legal Representative:{" "}
            {legal_Representative_fullName}{" "}
          </p>
          <p>Name of the Business:{businessName} </p>
          <p>Acronym: {acronym} </p>
          <p>Activity: {activity} </p>
          <p>Date of Registration: {registrationDate} </p>
          <p>Business and fiscal number: {businessAddress} </p>
          <p>Ethnicity: {ethnicAffiliation} </p>
          <p>Business Address: {businessAddress}</p>
          <p>Municipality: {"Prishtina"}</p>
          <p>Phone/e-mail & website: {phoneNumber}</p>
        </div>
        <div className="pdf-forms">
          <p>
            1.Describe your business. What do you do? What do you produce?{" "}
            {" " + businessDescription}
          </p>
          <p>
            2.What is the legal status of your business? (Is it trade,
            individual, partnership, L.L.C., etc.)?
            {" " + businessLegalStatus}
          </p>
          <p>3.Who are the owners of the business? {" " + businessOwners}</p>
          <p>
            4.Is it seasonal or continuous work? {" " + businessDescription}
          </p>
          <p>5.How many employees do you currently have? {employeeNumber}</p>
          <p>Permanent workers: {permanentWorkers} </p>
          <p>Female: {femaleEmployees} </p>
          <p>Youth: {youngEmployees}</p>
          <p>Minority: {minorityMembers}</p>
          <p>People with Disabilities: {persons_with_disabilities}</p>
          <p>Seasonal workers: {seasonalWorkers}</p>
          <p>
            6.Products/services (which products/services are you selling? What
            are expectations for your products/services in the future?){" "}
            {products_Services}
          </p>
          <p>
            7.Who are your customers? (Who do you sell to?) List if you have
            agreements or regular contract with a company that you usually
            supply. {customers}
          </p>
          <p>
            8.Please provide data on your sales for the years 2020, 2021 and
            2022. {sales_data}
          </p>
        </div>
        <div className="pdf-forms">
          <p>
            9.What do you need the grant for? What equipment/tools are you
            planning to buy? (List the equipment that you are planning to buy if
            you receive a grant?). <b>Note:</b> Used equipment are not allowed.
          </p>
          <div className="pdf-forms">
            <p>1. {devices_shop_place}</p>
            <p>2.</p>
            <p>3.</p>
          </div>
          <p>
            10. Describe the problem that will be resolved by grant support?{" "}
            {grant_help}
          </p>
          <p>
            11. What specific results will you achieve if you receive this
            grant? (provide quantitative data about production, sales, import
            substitution, job creation, etc.) {grant_need}
          </p>
          <p>
            12. How many employees are you planning to hire if you receive a
            grant? (please specify the position, ethnicity and gender){" "}
            {training_need}
          </p>
        </div>
        <div className="pdf-forms">
          <p>
            13. What is the cost of the equipment/tool that you are planning to
            buy? {total_cost}
          </p>
          <p>Total cost __________________________</p>
          <p>Amount of grant requested: ____________________</p>
          <p>Personal Contribution _____________________</p>
        </div>
        <div className="pdf-forms">
          <p>
            14. Where are you planning to buy the equipment/tools?{" "}
            {devices_shop_place}
          </p>
          <p></p>
          <p>
            15. Do you need training in the business field that you are in, if
            yes please specify what type of training? {taxNumber}
          </p>
          <p></p>
        </div>
        <div className="pdf-forms">
          <p>
            16. Have you ever received any assistance from NGOs, associations or
            other donor? If yes, describe! {specific_result}
          </p>
          <p></p>
        </div>
        <h5 style={{ fontStyle: "italic", margin: "10px" }}>
          I hereby certify that information provided is true and correct and I
          am aware that I may be disqualified from the process if it is proved
          different. ✓
        </h5>
        <h5 style={{ margin: "10px" }}>Date: 19.09.2023 </h5>
      </div>
    </div>
  );
});

export default ApplicationPdf;
