import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "../pages/login/Login";
import SignUp from "../pages/signup/SignUp";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import Grants from "../pages/grants/Grants";
import Award from "../pages/award/Award";
import Reporting from "../pages/reporting/Reporting";
import Resources from "../pages/resources/Resources";
import GrantWriting from "../pages/grantWriting/GrantWriting";
import GrantsForSme from "../pages/grantsForSme/GrantsForSme";
import GrantsForSmeSecond from "../pages/grants-for-sme-second/GrantsForSmeSecond";
import SuccesApplicationPhaseOne from "../pages/successPage/SuccessPage";
import SuccesApplicationPhaseSecond from "../pages/successPage/SuccesApplicationPhaseSecond";
import Admin from "../pages/admin/Admin";
import AdminListPhaseOne from "../components/admin/adminList/AdminListPhaseOne";
import AdminListPhaseSecond from "../components/admin/adminList/AdminListPhaseSecond";
import AdminVisitList from "../components/admin/adminList/adminVisitList";
import CreateNewPassword from "../pages/createNewPassword/CreateNewPassword";
import VerifyCodePage from "../pages/verifyCodePage/VerifyCodePage";

const AuthenticatedRoute = ({ component: Component, isAdmin, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (isAdmin && user?.user?.isAdmin !== true) {
    return <Redirect to="/grants" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (isAuthenticated) {
    return <Redirect to="/grants" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const SignUpRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default function Roots() {
  return (
    <div className="roots-container">
      <Router>
        <Switch>
          <AuthRoute path="/login" component={Login} />
          <SignUpRoute path="/signup" component={SignUp} />
          <AuthRoute path="/forgot-password" component={ForgotPassword} />
          <AuthRoute path="/new-password" component={CreateNewPassword} />
          <AuthRoute path="/verify-password" component={VerifyCodePage} />
          <AuthenticatedRoute exact path="/" component={Grants} />
          <AuthenticatedRoute path="/grants" component={Grants} />
          <AuthenticatedRoute path="/award" component={Award} />
          <AuthenticatedRoute path="/reporting" component={Reporting} />
          <AuthenticatedRoute path="/resources" component={Resources} />
          <AuthenticatedRoute
            path="/grant-writing-resources"
            component={GrantWriting}
          />
          <AuthenticatedRoute path="/grants-for-sme" component={GrantsForSme} />
          <AuthenticatedRoute
            path="/grants-for-sme-second"
            component={GrantsForSmeSecond}
          />
          <AuthenticatedRoute
            path="/success-first-phase"
            component={SuccesApplicationPhaseOne}
          />
          <AuthenticatedRoute
            path="/success-second-phase"
            component={SuccesApplicationPhaseSecond}
          />
          <AuthenticatedRoute path="/admin" isAdmin={true} component={Admin} />
          <AuthenticatedRoute
            path="/admin-phase-one"
            isAdmin={true}
            component={AdminListPhaseOne}
          />
          <AuthenticatedRoute
            path="/admin-phase-second"
            isAdmin={true}
            component={AdminListPhaseSecond}
          />
          <AuthenticatedRoute
            path="/admin-visit"
            isAdmin={true}
            component={AdminVisitList}
          />
          <Redirect to="/login" />
        </Switch>
      </Router>
    </div>
  );
}
