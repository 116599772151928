import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../components/button/Button";
import "./createNewPassword.scss";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

export default function NewPassword() {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState(null);

  useEffect(() => {
    try {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");

      if (token) {
        const decodedToken = jwtDecode(token);
        setEmail(decodedToken.email);
      }
    } catch (error) {
      console.error("Failed to decode token", error);
    }
  }, [location]);

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(
        "https://apigrant.bbros.al/api/password-resets",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...values, email }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      const data = await response.json();
      console.log("Success:", data);
      toast.success("Password reset successfully!");
      history.push("/login");
    } catch (error) {
      console.log("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmedPassword: "",
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string()
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
          .required("New password is required"),
        confirmedPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .required("Confirm password is required"),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <div className="new-password-container">
          <div className="new-password-form-container">
            <div className="new-password-title">
              <h3>Create New Password</h3>
            </div>
            <div className="new-password-form">
              <form onSubmit={formik.handleSubmit}>
                <div
                  className={`input-wrapper ${
                    formik.touched.newPassword && formik.errors.newPassword
                      ? "error"
                      : ""
                  }`}
                >
                  <Field
                    type="password"
                    name="newPassword"
                    className={
                      formik.touched.newPassword && formik.errors.newPassword
                        ? "error"
                        : ""
                    }
                  />
                  <label
                    htmlFor="newPassword"
                    className={`placeholder-label ${
                      formik.values.newPassword ? "active" : ""
                    }`}
                  >
                    New Password
                  </label>
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div
                  className={`input-wrapper ${
                    formik.touched.confirmedPassword &&
                    formik.errors.confirmedPassword
                      ? "error"
                      : ""
                  }`}
                >
                  <Field
                    type="password"
                    name="confirmedPassword"
                    className={
                      formik.touched.confirmedPassword &&
                      formik.errors.confirmedPassword
                        ? "error"
                        : ""
                    }
                  />
                  <label
                    htmlFor="confirmedPassword"
                    className={`placeholder-label ${
                      formik.values.confirmedPassword ? "active" : ""
                    }`}
                  >
                    Confirm Password
                  </label>
                  <ErrorMessage
                    name="confirmedPassword"
                    component="div"
                    className="error-message"
                  />
                </div>

                <Button
                  type="submit"
                  title={formik.isSubmitting ? "Loading..." : "Submit"}
                  disabled={formik.isSubmitting}
                />
              </form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
