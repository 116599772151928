import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./login.scss";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../components/button/Button";
import { useDispatch } from "react-redux";
import { login } from "../../store/features/authSlice";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSignIn = async (values) => {
    await dispatch(login(values.email, values.password, history));
  };

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };

 

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("Email is required"),
        password: Yup.string().required("Password is required"),
      })}
      onSubmit={handleSignIn}
    >
      {(formik) => (
        <div className="login-container-content">
          <div className="image-container">
            <img src="/assets/images/login.jpg" alt="Login Image" />
          </div>
          <div className="login-container">
            <img src="/assets/images/Logo.svg" alt="Logo svg" />
            <form onSubmit={formik.handleSubmit}>
              <div className="login-form-container">
                <div className="login-title">
                  <h2 style={{ color: "#333" }}>{t("applyNowForGrant")}</h2>
                </div>
                <div className="login-form">
                  <h3>{t("signIn")}</h3>
                  <div
                    className={`input-wrapper ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    <Field
                      type="text"
                      name="email"
                      className={
                        formik.touched.email && formik.errors.email
                          ? "error"
                          : ""
                      }
                    />
                    <label
                      className={`placeholder-label ${
                        formik.values.email ? "active" : ""
                      }`}
                    >
                      {t("email")}
                    </label>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div
                    className={`input-wrapper ${
                      formik.touched.email && formik.errors.email ? "error" : ""
                    }`}
                  >
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className={
                        formik.touched.password && formik.errors.password
                          ? "error"
                          : ""
                      }
                    />
                    <label
                      className={`placeholder-label ${
                        formik.values.password ? "active" : ""
                      }`}
                    >
                      {t("password")}
                    </label>
                    <span
                      className="password-toggle"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          className="password-icon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEye}
                          className="password-icon"
                        />
                      )}
                    </span>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="login-span-container">
                    <select
                      value={i18n.language}
                      onChange={handleLanguageChange}
                    >
                      <option value="al">AL</option>
                      <option value="en">EN</option>
                      <option value="sr">SR</option>
                    </select>

                    <span>
                      <a href="/forgot-password">{t("forgotPassword")}</a>
                    </span>
                  </div>
                  <Button
                    type="submit"
                    title={
                      formik.isSubmitting ? "Loading..." : `${t("signIn")}`
                    }
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                  />

                  <a href="/signup">
                    <p>
                      {t("notAMember")} <span>{t("signUpp")}</span>
                    </p>
                  </a>
                </div>
              </div>
            </form>
            <div className="login-footer">
              <hr className="login-footer-line" />
              <ul className="login-footer-menu">
                <li>
                  <a href="/">{t("home")}</a>
                </li>
                <li>
                  <a href="/about">{t("aboutUs")}</a>
                </li>
                <li>
                  <a href="/news">{t("news")}</a>
                </li>
                <li>
                  <a href="/contact">{t("contactUs")}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
