import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUserInterest = createAsyncThunk(
  "userInterest/fetchUserInterest",
  async (arg, { rejectWithValue }) => {
    const { userId, token } = arg;
    try {
      const response = await axios.get(
        `https://apigrant.bbros.al/api/users/userInterest/${userId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userInterestSlice = createSlice({
  name: "userInterest",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInterest.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserInterest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchUserInterest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userInterestSlice.reducer;
