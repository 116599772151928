import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../components/button/Button";
import Footer from "../../components/footer/Footer";
import TableForm from "../../components/table/Table";
import DynamicTable from "../../components/table/DynamicTable";
import IncomeTable from "../../components/table/IncomeTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function GrantsForSme() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const breadcrumbItems = [
    { label: "Grants Managment Platform", url: "/" },
    { label: "Grants", url: "/grants" },
    { label: "Full Grant Application" },
  ];
  const history = useHistory();
  const [storedFormValues, setStoredFormValues] = useState(null);
  const [validatedFormikValuesSecond, setValidatedFormikValuesSecond] =
    useState(null);
  const storedFullGrantValues = JSON.parse(
    localStorage.getItem("validatedFormikValuesSecond")
  );
  const user = useSelector((state) => state.auth.user);
  const [phazeFourFiles, setPhazeFourFiles] = useState();

  useEffect(() => {
    const storedStep = localStorage.getItem("currentStep");
    if (storedStep) {
      setCurrentStep(parseInt(storedStep));
    } else {
      localStorage.setItem("currentStep", currentStep.toString());
    }
  }, []);

  const handleFirstStep = (values) => {
    const organizationData = {
      createOrganizationDto: {
        Call_Number: values.Call_Number || "",
        Business_Name: values.Business_Name || "",
        Applicant_Full_Name: values.Applicant_Full_Name || "",
        Business_Address: values.Business_Address || "",
        Municipality: values.Municipality || "",
        Phone_Numbers: values.Phone_Numbers || 0,
        Email_Address: values.Email_Address || "",
        Contact_Person: values.Contact_Person || "",
        Contact_Person_Email: values.Contact_Person_Email || "",
        Contact_Person_Phone: values.Contact_Person_Phone || 0,
        Business_Registration_Year: values.Business_Registration_Year || 0,
        Business_Legal_Status: values.Business_Legal_Status || "",
        Registration_Number: values.Registration_Number || 0,
        Tax_Identification_Number: values.Tax_Identification_Number || 0,
        Business_Activity_Primary_Sectors:
          values.Business_Activity_Primary_Sectors || "",
        Employees_Number: values.Employees_Number || 0,
      },
    };
    setValidatedFormikValuesSecond(organizationData);
    localStorage.setItem(
      "validatedFormikValuesSecond",
      JSON.stringify(organizationData)
    );
    setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleSecondStep = (values) => {
    const applicationData = {
      createApplication: {
        title_proposed_grant_activity:
          values.title_proposed_grant_activity || "",
        projectLocation: values.projectLocation || "",
        executiveSummary: values.executiveSummary || "",
        JustificationBackground: values.JustificationBackground || "",
        your_customers: values.your_customers || "",
        agreements: values.agreements || "",
        purposeObjectivesGrantActivity:
          values.purposeObjectivesGrantActivity || "",
        genderMinorityCommunityAspects:
          values.genderMinorityCommunityAspects || "",
        expected_Results: values.expected_Results || "",
        marketDisturbance: values.marketDisturbance || "",
        brief_description_business: values.brief_description_business || "",
        business_experience: values.business_experience || "",
        interestId: localStorage.getItem("interestId"),
        municipalityId: 3,
      },
    };

    const tempObj = {
      ...validatedFormikValuesSecond,
      ...applicationData,
    };

    setValidatedFormikValuesSecond(tempObj);
    localStorage.setItem(
      "validatedFormikValuesSecond",
      JSON.stringify(tempObj)
    );
    setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleThirdStep = (values) => {
    const { createAnnualIncomeDto, createReferenceDto, budgetData } = values;

    const tempObj = {
      ...validatedFormikValuesSecond,
      createAnnualIncomeDto: createAnnualIncomeDto.map((item) => ({
        year: 2023,
        annualIncome: 2000,
      })),
      createReferenceDto: createReferenceDto.map((item) => ({
        fundingSourceName: "2333",
        contactPerson: "2333",
        grantProjectName: "2333",
        brief_Project_Description: "2333",
        project_Implementation_Period: "2333",
      })),
      budgetData: budgetData.map((item) => ({
        devices_Tools: "2333",
        quantity: 2,
        price_per_unit: 2,
        total_price: 4,
        personal_contribution: 2,
      })),
    };

    setValidatedFormikValuesSecond(tempObj);
    localStorage.setItem(
      "validatedFormikValuesSecond",
      JSON.stringify(tempObj)
    );
    setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [rows, setRows] = useState([
    {
      devices_Tools: "",
      quantity: "",
      price_per_unit: "",
      personal_contribution: "",
    },
  ]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        devices_Tools: "",
        quantity: "",
        price_per_unit: "",
        personal_contribution: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleSubmit = async (values) => {
    // const { createAnnualIncomeDto, createReferenceDto, budgetData } = values;

    const tempObj = {
      ...validatedFormikValuesSecond,
      createAnnualIncomeDto: [
        {
          year: 2022,
          annualIncome: 2022,
        },
      ],
      createReferenceDto: [
        {
          fundingSourceName: "string",
          contactPerson: "string",
          grantProjectName: "string",
          brief_Project_Description: "string",
          project_Implementation_Period: "string",
        },
      ],
      budgetData: [
        {
          devices_Tools: "string",
          quantity: "string",
          price_per_unit: "string",
          total_price: 2000,
          personal_contribution: "string",
        },
      ],
    };

    setValidatedFormikValuesSecond(tempObj);
    localStorage.setItem(
      "validatedFormikValuesSecond",
      JSON.stringify(tempObj)
    );
    // setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    try {
      const userId = user?.user?.id;
      const token = localStorage.getItem("access_token");

      const requestData = {
        ...tempObj,
        userId: userId,
        ...values,
      };

      const response = await axios.post(
        "https://apigrant.bbros.al/api/application/submitApplication",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const applicationId = response?.data[0]?.id;
      localStorage.setItem("applicationId", applicationId);
      toast.success("Form submitted successfully!");
      setCurrentStep(currentStep + 1);
      history.push("/grants-for-sme-second");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const handleSubmitUpload = async (values, { setSubmitting }) => {
    try {
      const uploadUrl = "https://apigrant.bbros.al/api/files/upload";
      const applicationId = localStorage.getItem("applicationId");

      const formData = new FormData();
      formData.append("applicationId", applicationId);

      Object.values(phazeFourFiles).forEach((value) => {
        if (value instanceof FileList) {
          for (let i = 0; i < value.length; i++) {
            formData.append("files", value[i]);
          }
        }
      });

      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      history.push("/grants");

      setSubmitting(false);
      toast.success("Files uploaded successfully!");
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  function setFiles(name, file) {
    let tempArrayOfFiles = {
      ...phazeFourFiles,
      [name]: file,
    };
    console.log(tempArrayOfFiles, "tempArrayOfFiles");
    setPhazeFourFiles(tempArrayOfFiles);
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Formik
            key={1}
            initialValues={
              storedFormValues || {
                Call_Number: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto.Call_Number
                  : "",
                Business_Name: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto.Business_Name
                  : "",
                Applicant_Full_Name:
                  user.user.firstName + " " + user.user.lastName,
                Business_Address: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto.Business_Address
                  : "",
                Municipality: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto.Municipality
                  : "",
                Phone_Numbers: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto.Phone_Numbers
                  : "",
                Email_Address: user?.user?.email,
                Contact_Person: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto.Contact_Person
                  : "",
                Contact_Person_Email: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .Contact_Person_Email
                  : "",
                Contact_Person_Phone: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .Contact_Person_Phone
                  : "",
                businessDescription: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .businessDescription
                  : "",
                Business_Registration_Year: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .Business_Registration_Year
                  : "",
                Business_Legal_Status: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .Business_Legal_Status
                  : "",

                Registration_Number: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .Registration_Number
                  : "",
                Tax_Identification_Number: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .Tax_Identification_Number
                  : "",
                Business_Activity_Primary_Sectors: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto
                      .Business_Activity_Primary_Sectors
                  : "",
                Employees_Number: storedFullGrantValues
                  ? storedFullGrantValues.createOrganizationDto.Employees_Number
                  : "",
              }
            }
            onSubmit={(values) => handleFirstStep(values)}
          >
            {(formik) => (
              <>
                <div className="grants-application-inputs">
                  <form onSubmit={formik.handleSubmit}>
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.apsNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Call_Number && formik.errors.Call_Number
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Call_Number"
                        className={
                          formik.touched.Call_Number &&
                          formik.errors.Call_Number
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Call_Number"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.businessName")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Business_Name &&
                        formik.errors.Business_Name
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Business_Name"
                        className={
                          formik.touched.Business_Name &&
                          formik.errors.Business_Name
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Business_Name"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.fullNameApplicant")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Applicant_Full_Name &&
                        formik.errors.Applicant_Full_Name
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        disabled
                        type="text"
                        name="Applicant_Full_Name"
                        className={
                          formik.touched.Applicant_Full_Name &&
                          formik.errors.Applicant_Full_Name
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Applicant_Full_Name"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.businessAddress")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Business_Address &&
                        formik.errors.Business_Address
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Business_Address"
                        className={
                          formik.touched.Business_Address &&
                          formik.errors.Business_Address
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Business_Address"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.municipality")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Municipality &&
                        formik.errors.Municipality
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Municipality"
                        className={
                          formik.touched.Municipality &&
                          formik.errors.Municipality
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Municipality"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.phoneNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Phone_Numbers &&
                        formik.errors.Phone_Numbers
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="Phone_Numbers"
                        className={
                          formik.touched.Phone_Numbers &&
                          formik.errors.Phone_Numbers
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Phone_Numbers"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.email")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Email_Address &&
                        formik.errors.Email_Address
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        disabled
                        type="text"
                        name="Email_Address"
                        className={
                          formik.touched.Email_Address &&
                          formik.errors.Email_Address
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Email_Address"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.contactPersonTitle")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Contact_Person &&
                        formik.errors.Contact_Person
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Contact_Person"
                        className={
                          formik.touched.Contact_Person &&
                          formik.errors.Contact_Person
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Contact_Person"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}

                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.contactPersonNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Contact_Person_Phone &&
                        formik.errors.Contact_Person_Phone
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="Contact_Person_Phone"
                        className={
                          formik.touched.Contact_Person_Phone &&
                          formik.errors.Contact_Person_Phone
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Contact_Person_Phone"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.contactEmail")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Contact_Person_Email &&
                        formik.errors.Contact_Person_Email
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Contact_Person_Email"
                        className={
                          formik.touched.Contact_Person_Email &&
                          formik.errors.Contact_Person_Email
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Contact_Person_Email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}

                    {/* {""}
                    <p className="paragraph-date">Business description</p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.businessDescription &&
                        formik.errors.businessDescription
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="businessDescription"
                        className={
                          formik.touched.businessDescription &&
                          formik.errors.businessDescription
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="businessDescription"
                        component="div"
                        className="error-message"
                      />
                    </div> */}
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.registrationYear")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Business_Registration_Year &&
                        formik.errors.Business_Registration_Year
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="Business_Registration_Year"
                        className={
                          formik.touched.Business_Registration_Year &&
                          formik.errors.Business_Registration_Year
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Business_Registration_Year"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.businessLegalStatus")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Business_Legal_Status &&
                        formik.errors.Business_Legal_Status
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Business_Legal_Status"
                        className={
                          formik.touched.Business_Legal_Status &&
                          formik.errors.Business_Legal_Status
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Business_Legal_Status"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}

                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.registationNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Business_Legal_Status &&
                        formik.errors.Business_Legal_Status
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="Registration_Number"
                        className={
                          formik.touched.Registration_Number &&
                          formik.errors.Registration_Number
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Registration_Number"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.taxId")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Business_Legal_Status &&
                        formik.errors.Business_Legal_Status
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="Tax_Identification_Number"
                        className={
                          formik.touched.Tax_Identification_Number &&
                          formik.errors.Tax_Identification_Number
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Tax_Identification_Number"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.primarySector")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Business_Activity_Primary_Sectors &&
                        formik.errors.Business_Activity_Primary_Sectors
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="Business_Activity_Primary_Sectors"
                        className={
                          formik.touched.Business_Activity_Primary_Sectors &&
                          formik.errors.Business_Activity_Primary_Sectors
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Business_Activity_Primary_Sectors"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("fullGrantApplication.employeesNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.Employees_Number &&
                        formik.errors.Employees_Number
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="Employees_Number"
                        className={
                          formik.touched.Employees_Number &&
                          formik.errors.Employees_Number
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="Employees_Number"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}

                    <Button
                      type="submit"
                      title={formik.isSubmitting ? "Loading..." : `${t("nextStep")}`}
                      disabled={formik.isSubmitting}
                    />
                  </form>
                </div>
              </>
            )}
          </Formik>
        );
      case 2:
        return (
          <>
            <Formik
              key={2}
              initialValues={
                storedFormValues || {
                  title_proposed_grant_activity: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication
                        ?.title_proposed_grant_activity
                    : "",
                  projectLocation: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication?.projectLocation
                    : "",
                  executiveSummary: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication?.executiveSummary
                    : "",
                  JustificationBackground: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication
                        ?.JustificationBackground
                    : "",
                  purposeObjectivesGrantActivity: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication
                        ?.purposeObjectivesGrantActivity
                    : "",
                  genderMinorityCommunityAspects: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication
                        ?.genderMinorityCommunityAspects
                    : "",
                  expected_Results: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication?.expected_Results
                    : "",
                  marketDisturbance: storedFullGrantValues
                    ? storedFullGrantValues?.createApplication
                        ?.marketDisturbance
                    : "",
                }
              }
              // validationSchema={Yup.object().shape({
              //   title_proposed_grant_activity: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   projectLocation: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   executiveSummary: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   JustificationBackground: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   purposeObjectivesGrantActivity: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   genderMinorityCommunityAspects: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   expected_Results: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   marketDisturbance: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   products_Services: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   customers: Yup.string().required("This Field is required *"),
              //   sales_data: Yup.string().required("This Field is required *"),
              // })}
              onSubmit={(values) => handleSecondStep(values)}
            >
              {(formik) => (
                <>
                  <div className="grants-application-inputs">
                    <form onSubmit={formik.handleSubmit}>
                      <p className="paragraph-date">
                        {t("fullGrantApplication.proposedGrantActivityTitle")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.title_proposed_grant_activity &&
                          formik.errors.title_proposed_grant_activity
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="title_proposed_grant_activity"
                          value={formik.values.title_proposed_grant_activity}
                          className={
                            formik.touched.title_proposed_grant_activity &&
                            formik.errors.title_proposed_grant_activity
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="title_proposed_grant_activity"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("fullGrantApplication.projectLocation")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.projectLocation &&
                          formik.errors.projectLocation
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="projectLocation"
                          value={formik.values.projectLocation}
                          className={
                            formik.touched.projectLocation &&
                            formik.errors.projectLocation
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="projectLocation"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("fullGrantApplication.executiveSummaryTitle")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.executiveSummaryDescription")}{" "}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.executiveSummary &&
                          formik.errors.executiveSummary
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="executiveSummary"
                          value={formik.values.executiveSummary}
                          className={
                            formik.touched.executiveSummary &&
                            formik.errors.executiveSummary
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="executiveSummary"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      {""}
                      <p className="paragraph-date">
                        {t("fullGrantApplication.describeCustomers")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.your_customers &&
                          formik.errors.your_customers
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="your_customers"
                          value={formik.values.your_customers}
                          className={
                            formik.touched.your_customers &&
                            formik.errors.your_customers
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="your_customers"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      {""}
                      <p className="paragraph-date">
                        {t("fullGrantApplication.agreements")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.agreements && formik.errors.agreements
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="agreements"
                          value={formik.values.agreements}
                          className={
                            formik.touched.agreements &&
                            formik.errors.agreements
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="agreements"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <p className="paragraph-date">
                        {t("fullGrantApplication.justificationTitle")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.justificationDescription")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.JustificationBackground &&
                          formik.errors.JustificationBackground
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="JustificationBackground"
                          value={formik.values.JustificationBackground}
                          className={
                            formik.touched.JustificationBackground &&
                            formik.errors.JustificationBackground
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="JustificationBackground"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <p className="paragraph-date">
                        {t("fullGrantApplication.grantGoalTitle")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.grantGoalDescription")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.purposeObjectivesGrantActivity &&
                          formik.errors.purposeObjectivesGrantActivity
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="purposeObjectivesGrantActivity"
                          value={formik.values.purposeObjectivesGrantActivity}
                          className={
                            formik.touched.purposeObjectivesGrantActivity &&
                            formik.errors.purposeObjectivesGrantActivity
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="purposeObjectivesGrantActivity"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <p className="paragraph-date">
                        {t("fullGrantApplication.genderMinorityTitle")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.genderMinorityDescription")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.genderMinorityCommunityAspects &&
                          formik.errors.genderMinorityCommunityAspects
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="genderMinorityCommunityAspects"
                          value={formik.values.genderMinorityCommunityAspects}
                          className={
                            formik.touched.genderMinorityCommunityAspects &&
                            formik.errors.genderMinorityCommunityAspects
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="genderMinorityCommunityAspects"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("fullGrantApplication.exceptedResultsTitle")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.exceptedResultsDescription")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.expected_Results &&
                          formik.errors.expected_Results
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="expected_Results"
                          value={formik.values.expected_Results}
                          className={
                            formik.touched.expected_Results &&
                            formik.errors.expected_Results
                              ? "error"
                              : ""
                          }
                        />

                        <ErrorMessage
                          name="expected_Results"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("fullGrantApplication.marketDistortionTitle")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.marketDistortionDescription")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.marketDisturbance &&
                          formik.errors.marketDisturbance
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="marketDisturbance"
                          value={formik.values.marketDisturbance}
                          className={
                            formik.touched.marketDisturbance &&
                            formik.errors.marketDisturbance
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="marketDisturbance"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <Button
                        type="submit"
                        title={
                          formik.isSubmitting ? "Loading..." : `${t("nextStep")}`
                        }
                        disabled={formik.isSubmitting}
                      />
                    </form>
                  </div>
                </>
              )}
            </Formik>
          </>
        );
      case 3:
        return (
          <>
            <Formik
              key={3}
              initialValues={
                storedFormValues || {
                  grant_need:
                    validatedFormikValuesSecond &&
                    validatedFormikValuesSecond.grant_need
                      ? validatedFormikValuesSecond.grant_need
                      : "",
                }
              }
              // validationSchema={Yup.object().shape({
              //   grant_need: Yup.string().required("This Field is required *"),
              //   future_devices: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   grant_help: Yup.string().required("This Field is required *"),
              //   specific_result: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   future_employees: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   total_cost: Yup.string().required("This Field is required *"),
              //   grant_sum: Yup.string().required("This Field is required *"),
              //   personal_contribute: Yup.string().required(
              //     "This Field is required *"
              //   ),
              //   devices_shop_place: Yup.string().required(
              //     "This Field is required *"
              //   ),
              // })}
              // onSubmit={(values) => handleThirdStep(values)}
              onSubmit={(values) => handleSubmit(null, values)}
            >
              {(formik) => (
                <>
                  <div className="grants-application-inputs">
                    <form onSubmit={formik.handleSubmit}>
                      <p>{t("fullGrantApplication.budgetProposal")}</p>
                      <p>
                        {t("fullGrantApplication.budgetProposalDescription")}
                      </p>
                      <TableForm
                        rows={rows}
                        handleInputChange={handleInputChange}
                        handleAddRow={handleAddRow}
                        handleRemoveRow={handleRemoveRow}
                        handleSubmit={handleSubmit}
                      />
                      {""}
                      <p className="paragraph-date">
                        {t("fullGrantApplication.briefDescriptionTitle")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.briefDescriptionParagraph")}{" "}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.brief_description_business &&
                          formik.errors.brief_description_business
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="brief_description_business"
                          value={formik.values.brief_description_business}
                          className={
                            formik.touched.brief_description_business &&
                            formik.errors.brief_description_business
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="brief_description_business"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      {""}

                      {""}
                      <p className="paragraph-date">
                        {t("fullGrantApplication.businessExperience")}
                      </p>
                      <p style={{ fontStyle: "italic" }}>
                        {t(
                          "fullGrantApplication.businessExperienceDescription"
                        )}{" "}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.business_experience &&
                          formik.errors.business_experience
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="business_experience"
                          value={formik.values.business_experience}
                          className={
                            formik.touched.business_experience &&
                            formik.errors.business_experience
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="business_experience"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      {""}
                      <b>{t("fullGrantApplication.references")}</b>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.referencesDescription")}
                      </p>
                      <DynamicTable />
                      <b>{t("fullGrantApplication.annualRevenue")}</b>
                      <p style={{ fontStyle: "italic" }}>
                        {t("fullGrantApplication.annualRevenueDescription")}
                      </p>
                      <IncomeTable />

                      <Button
                        type="submit"
                        title={
                          formik.isSubmitting ? "Loading..." : `${t("nextStep")}`
                        }
                        disabled={formik.isSubmitting}
                      />
                    </form>
                  </div>
                </>
              )}
            </Formik>
          </>
        );
      case 4:
        return (
          <>
            <Formik
              initialValues={{
                Business_Certificates: [],
                Personal_ID: [],
                Employee_List: [],
                Office_Images: [],
                Contract: [],
                Technical_Specifications: [],
                Equipments_Images: [],
                Offer_Equipments: [],
              }}
              onSubmit={(values) => handleSubmitUpload(null, values)}
            >
              {(formik) => (
                <>
                  <div className="grants-application-inputs">
                    <form onSubmit={formik.handleSubmit}>
                      {""}
                      <p>{t("fullGrantApplication.businessCertificates")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Business_Certificates"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      {""}
                      <p>{t("fullGrantApplication.kosovoID")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Personal_ID"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      {""}
                      <p>{t("fullGrantApplication.ktaList")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Employee_List"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      {""}
                      <p>{t("fullGrantApplication.picturesOfTheBusiness")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Office_Images"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      {""}
                      <p>{t("fullGrantApplication.contractual")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Contract"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      {""}
                      <p>{t("fullGrantApplication.equipmentDetail")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Technical_Specifications"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      {""}
                      <p>{t("fullGrantApplication.equipmentPictures")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Equipments_Images"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      {""}
                      <p>{t("fullGrantApplication.offerPicture")}</p>
                      <div className="input-wrapper">
                        <input
                          required
                          type="file"
                          multiple
                          name="Offer_Equipments"
                          onChange={(e) =>
                            setFiles(
                              e.target?.name,
                              e?.target.files ? e?.target.files : {},
                              e
                            )
                          }
                        />
                      </div>
                      {""}
                      <p style={{ color: "red" }}>
                        {t("fullGrantApplication.required")}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "stretch",
                          gap: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          required
                        />
                        <p> {t("eoiApplication.confirm")}</p>
                      </div>
                      <Button
                        type="submit"
                        title={formik.isSubmitting ? "Loading..." : `${t("submit")}`}
                        disabled={formik.isSubmitting}
                      />
                    </form>
                  </div>
                </>
              )}
            </Formik>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <div className="top-container">
        <Breadcrumbs items={breadcrumbItems} />
        <div className="grants-for-sme-container">
          <div className="container">
            <div className="grants-application">
              <div className="grants-application-form">
                {currentStep === 1 && (
                  <div className="grants-application-form-title">
                    <h2
                      style={{
                        textAlign: "justify",
                        color: "#333",
                        fontWeight: "300",
                        fontSize: "16px",
                      }}
                    >
                      {t("eoiApplication.dearApplicant")}
                      <br></br>
                      <br></br>
                      {t("fullGrantApplication.fullGrantfirst")}{" "}
                      <b>{t("fullGrantApplication.fullGrantfirstBold")}</b>
                      {t("fullGrantApplication.fullGrantParagraph")}
                      <br></br> <br></br> {t("eoiApplication.secondParagraph")}
                      <br></br>
                      <br></br>
                      {t("eoiApplication.thirdParagraph")}
                    </h2>
                  </div>
                )}
                <p style={{ float: "right", color: "#c32033" }}>
                  {t("applicationSteps")} {currentStep}/4
                </p>
                {renderStep()}
                <button onClick={handleSubmit}></button>
                {currentStep > 1 && (
                  <>
                    <div
                      className="grants-application-form-back"
                      onClick={handlePrevStep}
                    >
                      <FontAwesomeIcon icon={faArrowLeftLong} />
                      <p>Kthehu mrapa</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
