import "./modal.scss";

const Modal = ({
  isOpen,
  onClose,
  onConfirm,
  onReject,
  applicationId,
  actionType,
  content,
  setContent,
}) => {
  const handleConfirm = () => {
    onConfirm(applicationId);
    onClose();
  };

  const handleReject = () => {
    onReject(applicationId);
    onClose();
  };


  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <h3>Confirmation</h3>
        <p>
          Are you sure you want to{" "}
          {actionType === "approve" ? "approve" : "reject"} this application?
        </p>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Write email response"
        />
        <div className="modal-buttons">
          {actionType === "approve" && (
            <button onClick={handleConfirm}>Approve</button>
          )}
          {actionType === "reject" && (
            <button onClick={handleReject}>Yes</button>
          )}
          <button onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
