import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Button from "../../components/button/Button";
import Footer from "../../components/footer/Footer";
import TableForm from "../../components/table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createInterest } from "../../store/features/createInterest";
import "./grantsForSme.scss";
import { useTranslation } from "react-i18next";

export default function GrantsForSme() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const breadcrumbItems = [
    { label: "Grants Managment Platform", url: "/" },
    { label: "Grants", url: "/grants" },
    { label: "Grants for MMSMEss" },
    ``,
  ];
  const history = useHistory();
  const [storedFormValues, setStoredFormValues] = useState(null);
  const [step1Values, setStep1Values] = useState({});
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  useEffect(() => {
    const storedValues = localStorage.getItem("validatedFormikValues");
    if (storedValues) {
      setStoredFormValues(JSON.parse(storedValues));
    }
  }, []);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const storedStep = localStorage.getItem("currentStep");
    if (storedStep) {
      setCurrentStep(parseInt(storedStep));
    } else {
      localStorage.setItem("currentStep", currentStep.toString());
    }
  }, []);

  const handleFirstStep = (values) => {
    setStep1Values(values);
    // localStorage.setItem("validatedFormikValues", JSON.stringify(values));
    setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleSecondStep = (values) => {
    setStep2Values(values);
    setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleThirdStep = (values) => {
    setStep3Values(values);
    setCurrentStep(currentStep + 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const mergedValues = {
    ...step1Values,
    ...step2Values,
    ...step3Values,
  };

  useEffect(() => {
    if (mergedValues !== {}) {
      localStorage.setItem(
        "validatedFormikValues",
        JSON.stringify(mergedValues)
      );
    }
  }, [mergedValues]);

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [rows, setRows] = useState([
    {
      devices_Tools: "",
      quantity: "",
      price_per_unit: "",
      personal_contribution: "",
    },
  ]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        devices_Tools: "",
        quantity: "",
        price_per_unit: "",
        personal_contribution: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleSubmit = async (values) => {
    const userId = user?.user?.id;
    const requestData = {
      createInterestDto: {
        ...step1Values,
        ...step2Values,
        ...step3Values,
        training_need: "string",
        recived_help: "string",
        userId: userId,
      },
      devicesData: rows.map((row) => ({
        devices_Tools: row.devices_Tools,
        quantity: row.quantity,
        price_per_unit: row.price_per_unit,
        total_price: parseInt(row.quantity) * parseFloat(row.price_per_unit),
      })),
      ...values,
    };
    try {
      const response = await dispatch(createInterest(requestData));
      const interestId = response.payload;
      localStorage.setItem("interestId", interestId);
      history.push("/grants");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  const validatedFormikValues = localStorage.getItem("validateFormikValues");

  console.log(validatedFormikValues);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Formik
            key={1}
            initialValues={
              storedFormValues || {
                legal_Representative_fullName:
                  user?.user?.firstName + " " + user?.user?.lastName,
                businessName: validatedFormikValues
                  ? validatedFormikValues.businessName
                  : "",
                acronym: validatedFormikValues
                  ? validatedFormikValues.acronym
                  : "",
                activity: validatedFormikValues
                  ? validatedFormikValues.activity
                  : "",
                registrationDate: validatedFormikValues
                  ? validatedFormikValues.registrationDate
                  : "",
                businessNumber: validatedFormikValues
                  ? validatedFormikValues.businessNumber
                  : "",
                taxNumber: validatedFormikValues
                  ? validatedFormikValues.taxNumber
                  : "",
                ethnicAffiliation: validatedFormikValues
                  ? validatedFormikValues.ethnicAffiliation
                  : "",
                businessAddress: validatedFormikValues
                  ? validatedFormikValues.businessAddress
                  : "",
                municipality: validatedFormikValues
                  ? validatedFormikValues.municipality
                  : "",
                phoneNumber: validatedFormikValues
                  ? validatedFormikValues.phoneNumber
                  : "",
                email: user?.user?.email,
                businessDescription: validatedFormikValues
                  ? validatedFormikValues.businessDescription
                  : "",
                businessLegalStatus: validatedFormikValues
                  ? validatedFormikValues.businessLegalStatus
                  : "",
                businessOwners: validatedFormikValues
                  ? validatedFormikValues.businessOwners
                  : "",
              }
            }
            validationSchema={Yup.object().shape({
              legal_Representative_fullName: Yup.string().required(
                `${t("requiredField")}`
              ),
              businessName: Yup.string().required(
                `${t("requiredField")}`
              ),
              acronym: Yup.string().required(`${t("requiredField")}`),
              activity: Yup.string().required(`${t("requiredField")}`),
              registrationDate: Yup.string().required(
                `${t("requiredField")}`
              ),
              businessNumber: Yup.string().required(
                `${t("requiredField")}`
              ),
              taxNumber: Yup.string().required(`${t("requiredField")}`),
              ethnicAffiliation: Yup.string().required(`${t("requiredField")}`),
              businessAddress: Yup.string().required(
                `${t("requiredField")}`
              ),
              phoneNumber: Yup.string().required(`${t("requiredField")}`),
              municipality: Yup.string().required(`${t("requiredField")}`),
              email: Yup.string().required(`${t("requiredField")}`),
              businessDescription: Yup.string().required(
                `${t("requiredField")}`
              ),
              businessLegalStatus: Yup.string().required(
                `${t("requiredField")}`
              ),
              businessOwners: Yup.string().required(
                `${t("requiredField")}`
              ),
            })}
            onSubmit={(values) => handleFirstStep(values)}
          >
            {(formik) => (
              <>
                <div className="grants-application-inputs">
                  <form onSubmit={formik.handleSubmit}>
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.legalName")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.legal_Representative_fullName &&
                        formik.errors.legal_Representative_fullName
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        disabled
                        type="text"
                        name="legal_Representative_fullName"
                        value={formik.values.legal_Representative_fullName}
                        className={
                          formik.touched.legal_Representative_fullName &&
                          formik.errors.legal_Representative_fullName
                            ? "error"
                            : ""
                        }
                      />

                      <ErrorMessage
                        name="legal_Representative_fullName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.businessName")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.businessName &&
                        formik.errors.businessName
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="businessName"
                        className={
                          formik.touched.businessName &&
                          formik.errors.businessName
                            ? "error"
                            : ""
                        }
                      />

                      <ErrorMessage
                        name="businessName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.acronym")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.acronym && formik.errors.acronym
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="acronym"
                        className={
                          formik.touched.acronym && formik.errors.acronym
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="acronym"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.businessActivity")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.activity && formik.errors.activity
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="activity"
                        className={
                          formik.touched.activity && formik.errors.activity
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="activity"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.registrationDate")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.registrationDate &&
                        formik.errors.registrationDate
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="date"
                        name="registrationDate"
                        className={
                          formik.touched.registrationDate &&
                          formik.errors.registrationDate
                            ? "error"
                            : ""
                        }
                      />
                      <label
                        htmlFor="registrationDate"
                        className={`placeholder-label ${
                          formik.values.registrationDate ? "active" : ""
                        }`}
                      ></label>
                      <ErrorMessage
                        name="registrationDate"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {" "}
                      {t("eoiApplication.businessNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.businessNumber &&
                        formik.errors.businessNumber
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="businessNumber"
                        className={
                          formik.touched.businessNumber &&
                          formik.errors.businessNumber
                            ? "error"
                            : ""
                        }
                      />

                      <ErrorMessage
                        name="businessNumber"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.fiscalNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.taxNumber && formik.errors.taxNumber
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="taxNumber"
                        className={
                          formik.touched.taxNumber && formik.errors.taxNumber
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="taxNumber"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.ethnicity")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.ethnicAffiliation &&
                        formik.errors.ethnicAffiliation
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="ethnicAffiliation"
                        className={
                          formik.touched.ethnicAffiliation &&
                          formik.errors.ethnicAffiliation
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="ethnicAffiliation"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.businessAddress")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.businessAddress &&
                        formik.errors.businessAddress
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="businessAddress"
                        className={
                          formik.touched.businessAddress &&
                          formik.errors.businessAddress
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="businessAddress"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.municipality")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.municipality &&
                        formik.errors.municipality
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="municipality"
                        className={
                          formik.touched.municipality &&
                          formik.errors.municipality
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="municipality"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.phoneNumber")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="number"
                        name="phoneNumber"
                        className={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.emailWebsite")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.email && formik.errors.email
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        disabled
                        type="text"
                        name="email"
                        className={
                          formik.touched.email && formik.errors.email
                            ? "error"
                            : ""
                        }
                      />

                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.businessDescription")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.businessDescription &&
                        formik.errors.businessDescription
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="businessDescription"
                        className={
                          formik.touched.businessDescription &&
                          formik.errors.businessDescription
                            ? "error"
                            : ""
                        }
                      />

                      <ErrorMessage
                        name="businessDescription"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.businessLegalStatus")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.businessLegalStatus &&
                        formik.errors.businessLegalStatus
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="businessLegalStatus"
                        className={
                          formik.touched.businessLegalStatus &&
                          formik.errors.businessLegalStatus
                            ? "error"
                            : ""
                        }
                      />
                      <label
                        htmlFor="businessLegalStatus"
                        className={`placeholder-label ${
                          formik.values.businessLegalStatus ? "active" : ""
                        }`}
                      ></label>
                      <ErrorMessage
                        name="businessLegalStatus"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}
                    {""}
                    <p className="paragraph-date">
                      {t("eoiApplication.businessOwners")}
                    </p>
                    <div
                      className={`input-wrapper ${
                        formik.touched.businessOwners &&
                        formik.errors.businessOwners
                          ? "error"
                          : ""
                      }`}
                    >
                      <Field
                        type="text"
                        name="businessOwners"
                        className={
                          formik.touched.businessOwners &&
                          formik.errors.businessOwners
                            ? "error"
                            : ""
                        }
                      />
                      <ErrorMessage
                        name="businessOwners"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {""}

                    <Button
                      type="submit"
                      title={formik.isSubmitting ? "Loading..." : `${t("nextStep")}`}
                      disabled={formik.isSubmitting}
                    />
                  </form>
                </div>
              </>
            )}
          </Formik>
        );
      case 2:
        return (
          <>
            <Formik
              key={2}
              initialValues={
                storedFormValues || {
                  seasonal_or_ongoing: validatedFormikValues
                    ? validatedFormikValues.seasonal_or_ongoing
                    : "",
                  employeeNumber: validatedFormikValues
                    ? validatedFormikValues.employeeNumber
                    : "",
                  permanentWorkers: validatedFormikValues
                    ? validatedFormikValues.permanentWorkers
                    : "",
                  femaleEmployees: validatedFormikValues
                    ? validatedFormikValues.femaleEmployees
                    : "",
                  youngEmployees: validatedFormikValues
                    ? validatedFormikValues.youngEmployees
                    : "",
                  minorityMembers: validatedFormikValues
                    ? validatedFormikValues.minorityMembers
                    : "",
                  persons_with_disabilities: validatedFormikValues
                    ? validatedFormikValues.persons_with_disabilities
                    : "",
                  seasonalWorkers: validatedFormikValues
                    ? validatedFormikValues.seasonalWorkers
                    : "",
                  products_Services: validatedFormikValues
                    ? validatedFormikValues.products_Services
                    : "",
                  customers: validatedFormikValues
                    ? validatedFormikValues.customers
                    : "",
                  sales_data: validatedFormikValues
                    ? validatedFormikValues.sales_data
                    : "",
                }
              }
              validationSchema={Yup.object().shape({
                seasonal_or_ongoing: Yup.string().required(
                  `${t("requiredField")}`
                ),
                employeeNumber: Yup.string().required(
                  `${t("requiredField")}`
                ),
                permanentWorkers: Yup.string().required(
                  `${t("requiredField")}`
                ),
                femaleEmployees: Yup.string().required(
                  `${t("requiredField")}`
                ),
                youngEmployees: Yup.string().required(
                  `${t("requiredField")}`
                ),
                minorityMembers: Yup.string().required(
                  `${t("requiredField")}`
                ),
                persons_with_disabilities: Yup.string().required(
                  `${t("requiredField")}`
                ),
                seasonalWorkers: Yup.string().required(
                  `${t("requiredField")}`
                ),
                products_Services: Yup.string().required(
                  `${t("requiredField")}`
                ),
                customers: Yup.string().required(`${t("requiredField")}`),
                sales_data: Yup.string().required(`${t("requiredField")}`),
              })}
              onSubmit={(values) => handleSecondStep(values)}
            >
              {(formik) => (
                <>
                  <div className="grants-application-inputs">
                    <form onSubmit={formik.handleSubmit}>
                      <p className="paragraph-date">
                        {t("eoiApplication.seasonalWork")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.seasonal_or_ongoing &&
                          formik.errors.seasonal_or_ongoing
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="seasonal_or_ongoing"
                          value={formik.values.seasonal_or_ongoing}
                          className={
                            formik.touched.seasonal_or_ongoing &&
                            formik.errors.seasonal_or_ongoing
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="seasonal_or_ongoing"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.employeeNumber")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.employeeNumber &&
                          formik.errors.employeeNumber
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="employeeNumber"
                          value={formik.values.employeeNumber}
                          className={
                            formik.touched.employeeNumber &&
                            formik.errors.employeeNumber
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="employeeNumber"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.permanentWorkers")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.permanentWorkers &&
                          formik.errors.permanentWorkers
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="permanentWorkers"
                          value={formik.values.permanentWorkers}
                          className={
                            formik.touched.permanentWorkers &&
                            formik.errors.permanentWorkers
                              ? "error"
                              : ""
                          }
                        />

                        <ErrorMessage
                          name="permanentWorkers"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.femaleWorkers")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.femaleEmployees &&
                          formik.errors.femaleEmployees
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="femaleEmployees"
                          value={formik.values.femaleEmployees}
                          className={
                            formik.touched.femaleEmployees &&
                            formik.errors.femaleEmployees
                              ? "error"
                              : ""
                          }
                        />

                        <ErrorMessage
                          name="femaleEmployees"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.youthWorkers")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.youngEmployees &&
                          formik.errors.youngEmployees
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="youngEmployees"
                          value={formik.values.youngEmployees}
                          className={
                            formik.touched.youngEmployees &&
                            formik.errors.youngEmployees
                              ? "error"
                              : ""
                          }
                        />

                        <ErrorMessage
                          name="youngEmployees"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.minorityMembers")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.minorityMembers &&
                          formik.errors.minorityMembers
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="minorityMembers"
                          value={formik.values.minorityMembers}
                          className={
                            formik.touched.minorityMembers &&
                            formik.errors.minorityMembers
                              ? "error"
                              : ""
                          }
                        />

                        <ErrorMessage
                          name="minorityMembers"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.peopleWithDisabilities")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.persons_with_disabilities &&
                          formik.errors.persons_with_disabilities
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="persons_with_disabilities"
                          value={formik.values.persons_with_disabilities}
                          className={
                            formik.touched.persons_with_disabilities &&
                            formik.errors.persons_with_disabilities
                              ? "error"
                              : ""
                          }
                        />

                        <ErrorMessage
                          name="persons_with_disabilities"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.seasonalWorkers")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.seasonalWorkers &&
                          formik.errors.seasonalWorkers
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="seasonalWorkers"
                          value={formik.values.seasonalWorkers}
                          className={
                            formik.touched.seasonalWorkers &&
                            formik.errors.seasonalWorkers
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="seasonalWorkers"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.productServices")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.products_Services &&
                          formik.errors.products_Services
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          as="textarea"
                          type="text"
                          name="products_Services"
                          value={formik.values.products_Services}
                          className={
                            formik.touched.products_Services &&
                            formik.errors.products_Services
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="products_Services"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.customersType")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.customers && formik.errors.customers
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          as="textarea"
                          type="text"
                          name="customers"
                          value={formik.values.customers}
                          className={
                            formik.touched.customers && formik.errors.customers
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="customers"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.salesData")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.sales_data && formik.errors.sales_data
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          as="textarea"
                          type="text"
                          name="sales_data"
                          value={formik.values.sales_data}
                          className={
                            formik.touched.sales_data &&
                            formik.errors.sales_data
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="sales_data"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <Button
                        type="submit"
                        title={formik.isSubmitting ? "Loading..." : `${t("nextStep")}`}
                        disabled={formik.isSubmitting}
                      />
                    </form>
                  </div>
                </>
              )}
            </Formik>
          </>
        );
      case 3:
        return (
          <>
            <Formik
              key={3}
              initialValues={
                storedFormValues || {
                  grant_need:
                    validatedFormikValues && validatedFormikValues.grant_need
                      ? validatedFormikValues.grant_need
                      : "",
                  future_devices:
                    validatedFormikValues &&
                    validatedFormikValues.future_devices
                      ? validatedFormikValues.future_devices
                      : "",
                  grant_help:
                    validatedFormikValues && validatedFormikValues.grant_help
                      ? validatedFormikValues.grant_help
                      : "",
                  specific_result:
                    validatedFormikValues &&
                    validatedFormikValues.specific_result
                      ? validatedFormikValues.specific_result
                      : "",
                  future_employees:
                    validatedFormikValues &&
                    validatedFormikValues.future_employees
                      ? validatedFormikValues.future_employees
                      : "",
                  total_cost:
                    validatedFormikValues && validatedFormikValues.total_cost
                      ? validatedFormikValues.total_cost
                      : "",
                  grant_sum:
                    validatedFormikValues && validatedFormikValues.grant_sum
                      ? validatedFormikValues.grant_sum
                      : "",
                  personal_contribute:
                    validatedFormikValues &&
                    validatedFormikValues.personal_contribute
                      ? validatedFormikValues.personal_contribute
                      : "",
                  devices_shop_place:
                    validatedFormikValues &&
                    validatedFormikValues.devices_shop_place
                      ? validatedFormikValues.devices_shop_place
                      : "",
                }
              }
              validationSchema={Yup.object().shape({
                grant_help: Yup.string().required(`${t("requiredField")}`),
                specific_result: Yup.string().required(
                  `${t("requiredField")}`
                ),
                future_employees: Yup.string().required(
                  `${t("requiredField")}`
                ),
                total_cost: Yup.string().required(`${t("requiredField")}`),
                grant_sum: Yup.string().required(`${t("requiredField")}`),
                personal_contribute: Yup.string().required(
                  `${t("requiredField")}`
                ),
                devices_shop_place: Yup.string().required(
                  `${t("requiredField")}`
                ),
              })}
              onSubmit={(values) => handleThirdStep(values)}
            >
              {(formik) => (
                <>
                  <div className="grants-application-inputs">
                    <form onSubmit={formik.handleSubmit}>
                      <p className="paragraph-date">
                        {t("eoiApplication.devicestable")}
                      </p>
                      <TableForm
                        rows={rows}
                        handleInputChange={handleInputChange}
                        handleAddRow={handleAddRow}
                        handleRemoveRow={handleRemoveRow}
                        handleSubmit={handleSubmit}
                      />

                      <p className="paragraph-date">
                        {t("eoiApplication.grantProblem")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.grant_help && formik.errors.grant_help
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          as="textarea"
                          type="text"
                          name="grant_help"
                          value={formik.values.grant_help}
                          className={
                            formik.touched.grant_help &&
                            formik.errors.grant_help
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="grant_help"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.grantResults")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.specific_result &&
                          formik.errors.specific_result
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="specific_result"
                          value={formik.values.specific_result}
                          className={
                            formik.touched.specific_result &&
                            formik.errors.specific_result
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="specific_result"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.hireEmployees")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.future_employees &&
                          formik.errors.future_employees
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="future_employees"
                          value={formik.values.future_employees}
                          className={
                            formik.touched.future_employees &&
                            formik.errors.future_employees
                              ? "error"
                              : ""
                          }
                        />

                        <ErrorMessage
                          name="future_employees"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.equipmentTools")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.total_cost && formik.errors.total_cost
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="total_cost"
                          value={formik.values.total_cost}
                          className={
                            formik.touched.total_cost &&
                            formik.errors.total_cost
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="total_cost"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.grantAmountRequested")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.grant_sum && formik.errors.grant_sum
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="number"
                          name="grant_sum"
                          value={formik.values.grant_sum}
                          className={
                            formik.touched.grant_sum && formik.errors.grant_sum
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="grant_sum"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.personalContribution")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.personal_contribute &&
                          formik.errors.personal_contribute
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="personal_contribute"
                          value={formik.values.personal_contribute}
                          className={
                            formik.touched.personal_contribute &&
                            formik.errors.personal_contribute
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="personal_contribute"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.planningBuy")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.devices_shop_place &&
                          formik.errors.devices_shop_place
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="devices_shop_place"
                          value={formik.values.devices_shop_place}
                          className={
                            formik.touched.devices_shop_place &&
                            formik.errors.devices_shop_place
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="devices_shop_place"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <Button
                        type="submit"
                        title={formik.isSubmitting ? "Loading..." : `${t("nextStep")}`}
                        disabled={formik.isSubmitting}
                      />
                    </form>
                  </div>
                </>
              )}
            </Formik>
          </>
        );
      case 4:
        return (
          <>
            <Formik
              initialValues={
                storedFormValues || {
                  training_need:
                    validatedFormikValues && validatedFormikValues.training_need
                      ? validatedFormikValues.training_need
                      : "",
                  recived_help:
                    validatedFormikValues && validatedFormikValues.recived_help
                      ? validatedFormikValues.recived_help
                      : "",
                }
              }
              validationSchema={Yup.object().shape({
                training_need: Yup.string().required(
                  `${t("requiredField")}`
                ),
              })}
              onSubmit={(values) => handleSubmit(null, values)}
            >
              {(formik) => (
                <>
                  <div className="grants-application-inputs">
                    <form onSubmit={formik.handleSubmit}>
                      <p className="paragraph-date">
                        {t("eoiApplication.trainingNeed")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.training_need &&
                          formik.errors.training_need
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="training_need"
                          value={formik.values.training_need}
                          className={
                            formik.touched.training_need &&
                            formik.errors.training_need
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="training_need"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <p className="paragraph-date">
                        {t("eoiApplication.ngoDonor")}
                      </p>
                      <div
                        className={`input-wrapper ${
                          formik.touched.recived_help &&
                          formik.errors.recived_help
                            ? "error"
                            : ""
                        }`}
                      >
                        <Field
                          type="text"
                          name="recived_help"
                          value={formik.values.recived_help}
                          className={
                            formik.touched.recived_help &&
                            formik.errors.recived_help
                              ? "error"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="recived_help"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "stretch",
                          gap: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          required
                        />
                        <p> {t("eoiApplication.confirm")}</p>
                      </div>
                      <Button
                        type="submit"
                        title={formik.isSubmitting ? "Loading..." : `${t('submit')}`}
                        disabled={formik.isSubmitting}
                      />
                    </form>
                  </div>
                </>
              )}
            </Formik>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <div className="top-container">
        <Breadcrumbs items={breadcrumbItems} />
        <div className="grants-for-sme-container">
          <div className="container">
            <div className="grants-application">
              <div className="grants-application-form">
                {currentStep === 1 && (
                  <div className="grants-application-form-title">
                    <h2
                      style={{
                        textAlign: "justify",
                        color: "#333",
                        fontWeight: "300",
                        fontSize: "16px",
                      }}
                    >
                      {t("eoiApplication.dearApplicant")} <br></br>
                      <br></br>{" "}
                      <b style={{ fontWeight: "900" }}>
                        {" "}
                        {t("eoiApplication.grantExpressionBold")}
                      </b>{" "}
                      {t("eoiApplication.firstParagraph")}
                      <br></br>
                      <br></br>
                      {t("eoiApplication.secondParagraph")}
                      <br></br>
                      <br></br>
                      {t("eoiApplication.thirdParagraph")}
                    </h2>
                  </div>
                )}
                <p style={{ float: "right", color: "#c32033" }}>
                  {t("applicationSteps")} {currentStep}/4
                </p>

                {renderStep()}
                {/* {currentStep !== 4 && (
                  <Button title=`${t("nextStep")}` onClick={handleNextStep} />
                )} */}
                {currentStep > 1 && (
                  <>
                    <div
                      className="grants-application-form-back"
                      onClick={handlePrevStep}
                    >
                      <FontAwesomeIcon icon={faArrowLeftLong} />
                      <p>Kthehu mrapa</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
