import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonEn from './translations/en/translation.json';
import commonAl from './translations/al/translation.json';
import commonSr from './translations/sr/translation.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: commonEn
  },
  al: {
    translation: commonAl
  },
  sr : {
    translation : commonSr
  }
};


i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: "al", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;