import React from "react";
import "./progressCard.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function FullApplicationProgressCard() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const firstName = user?.user?.firstName

  return (
    <div className="progress-card-container">
      <div className="progress-card-info">
        <div className="progress-card-button">
          <div className="progress-card-button-first">
            <button className="button-card-open">{t('fullApplicationProgressCard.inReviewButton')}</button>
          </div>
          <div className="progress-card-button-first"></div>
        </div>
        <h3>
        { firstName }
          {t('fullApplicationProgressCard.greeting')}
        </h3>
        <p className="progress-card-details-paragraph">
          {t('fullApplicationProgressCard.progressBarDescription')}
        </p>
        <div className="progress-bar-container">
          <div className="progress-bar-background">
            <div className="progress-bar" style={{ width: "75%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
