import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const IncomeTable = () => {
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState([{ year: "", annualIncome: "0" }]);
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([...rows, { year: 0, annualIncome: 0 }]);
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>{t('incomeTable.year')}</th>
            <th>{t('incomeTable.annualRevenue')}</th>
            <th>{t('incomeTable.action')}</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="year"
                  value={row.year}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <input
                  style={{ width: "100%" }}
                  type="number"
                  name="annualIncome"
                  value={row.annualIncome}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </td>
              <td>
                <button
                  style={{ width: "100%" }}
                  onClick={() => removeRow(index)}
                >
                  {t('incomeTable.remove')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={addRow} type="button" className="addNewRow">
      {t('incomeTable.addRow')}
      </button>
    </div>
  );
};

export default IncomeTable;
