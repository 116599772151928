import React from 'react';
import './breadcrumbs.scss';

const Breadcrumbs = ({ items }) => {
  return (
    <nav aria-label="breadcrumb" className="breadcrumbs">
      <div className="container">
        <ol className="breadcrumb-list">
          {items.map((item, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${index === items.length - 1 ? 'active' : ''}`}
              aria-current={index === items.length - 1 ? 'page' : undefined}
            >
              {item.url ? <a href={item.url}>{item.label}</a> : item.label}
            </li>
          ))}
        </ol>
      </div>
    </nav>
  );
};

export default Breadcrumbs;
