import React, { useState } from "react";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/features/authSlice";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const [isHamburgerOpen, setHamburgerOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleItemClick = (url) => {
    setActiveItem(url);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const toggleHamburger = () => {
    setHamburgerOpen((prevState) => !prevState);
  };

  return (
    <div className="navbar-container">
      <div className="container">
        <div className="navbar-right-left">
          <div className="navbar-right">
            <div className="navbar-logo">
              <Link to="/">
                <img src="/assets/images/logo-grant.svg" alt="logo-grant" />
              </Link>
            </div>
            <div className="navbar-right-services">
              {user?.user?.isAdmin === false && (
                <ul>
                  <Link to="/grants">
                    <li
                      className={activeItem === "/grants" ? "active" : ""}
                      onClick={() => handleItemClick("/grants")}
                    >
                      <img
                        src="/assets/images/icons/ChartPieSlice.png"
                        alt="Logo 1"
                      />{" "}
                      {t("grant")}
                    </li>
                  </Link>
                  {/* <Link to="/reporting">
                    <li
                      className={activeItem === "/reporting" ? "active" : ""}
                      onClick={() => handleItemClick("/reporting")}
                    >
                      <img
                        src="/assets/images/icons/Notebook.png"
                        alt="Logo 3"
                      />
                      Reporting
                    </li>
                  </Link> */}
                  <a
                    href="https://kcdf.org/wp-content/uploads/2023/04/001_IEE_APS-2023-01_FAQ_English.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <li className={activeItem === "/faq" ? "active" : ""}>
                      <img
                        src="/assets/images/icons/BookOpen.png"
                        alt="Logo 3"
                      />
                      FAQs
                    </li>
                  </a>
                </ul>
              )}
            </div>
          </div>
          <div className="navbar-left">
            <div className="navbar-profile">
              {isAuthenticated ? (
                <button onClick={handleLogout}>
                  <img src="/assets/images/icons/UserCircle.png" alt="" />
                  {t("logout")} 
                </button>
              ) : (
                <Link to="/login">
                  <button>
                    <img src="/assets/images/icons/UserCircle.png" alt="" />
                    {t("login")}
                  </button>
                </Link>
              )}
            </div>
            <div className="select-language">
              <select
                value={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
              >
                <option value="al">AL</option>
                <option value="en">EN</option>
                <option value="sr">SR</option>
              </select>
            </div>
            <div
              className={`navbar-hamburger ${
                window.innerWidth <= 768 && isHamburgerOpen ? "active" : ""
              }`}
              onClick={toggleHamburger}
            >
              <div className="hamburger-bar"></div>
              <div className="hamburger-bar"></div>
              <div className="hamburger-bar"></div>
            </div>
            {window.innerWidth <= 768 && (
              <div
                className={`navbar-hamburger-menu ${
                  isHamburgerOpen ? "active" : ""
                }`}
              >
                <ul>
                  <li>
                    <img
                      src="/assets/images/icons/ChartPieSlice.png"
                      alt="Logo 1"
                    />
                    <Link to="/grants">Grants</Link>
                  </li>
                  <li>
                    <img src="/assets/images/icons/Notebook.png" alt="Logo 3" />
                    <Link to="/reporting">Reporting</Link>
                  </li>
                  <li>
                    <img src="/assets/images/icons/BookOpen.png" alt="Logo 3" />
                    <a
                      href="https://kcdf.org/wp-content/uploads/2023/04/001_IEE_APS-2023-01_FAQ_English.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
