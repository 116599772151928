import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./applicationPdf.scss";
import { BeatLoader } from "react-spinners";

const ApplicationPdfSecond = ({ reff, application }) => {
  const [responseData, setResponseData] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const token = user?.user?.access_token;
  const applicationId =
    application?.id || localStorage.getItem("applicationId");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://apigrant.bbros.al/api/application/findApplicationById/${applicationId}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setResponseData(response.data);
        setLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, [applicationId]);

  if (!responseData) {
    return <BeatLoader color="red" loading={loading} />;
  }

  const {
    title_proposed_grant_activity,
    projectLocation,
    executiveSummary,
    JustificationBackground,
    purposeObjectivesGrantActivity,
    genderMinorityCommunityAspects,
    expected_Results,
    marketDisturbance,
    organization,
    budget,
    annual_income,
    references,
  } = responseData;

  return (
    <>
      <div ref={reff} className="pdf-container">
        <div className="pdf-logos">
          <img src={require("../../images/logo-grant.png")} alt="kendok"></img>
          <img src={require("../../images/logo-grant.png")} alt="kendok"></img>
        </div>
        <div className="pdf-first-paragraph">
          <h3>
            Zhvillimi Gjitheperfshires Ekonomik Formulari i plote i aplikacionit
            per grant
          </h3>
        </div>
        <div className="pdf-forms">
          <p>
            Title of Proposed Grant Activity: {title_proposed_grant_activity}
          </p>
          <p>Project Location: {projectLocation}</p>
          <p>Executive Summary: {executiveSummary}</p>
          <p>Justification and Background: {JustificationBackground}</p>
          <p>
            Purpose and Objectives of Grant Activity:{" "}
            {purposeObjectivesGrantActivity}
          </p>
          <p>
            Gender and Minority Community Aspects:{" "}
            {genderMinorityCommunityAspects}
          </p>
          <p>Expected Results: {expected_Results}</p>
          <p>Market Disturbance: {marketDisturbance}</p>
        </div>

        <div className="pdf-forms">
          {organization.map((org) => (
            <div key={org.id}>
              <p>Call Number: {org.Call_Number}</p>
              <p>Business Name: {org.Business_Name}</p>
              <p>Applicant Full Name: {org.Applicant_Full_Name}</p>
              <p>Business Address: {org.Business_Address}</p>
              <p>Phone Numbers: {org.Phone_Numbers}</p>
              <p>Email Address: {org.Email_Address}</p>
              <p>Contact Person: {org.Contact_Person}</p>
              <p>Contact Person Email: {org.Contact_Person_Email}</p>
              <p>Contact Person Phone: {org.Contact_Person_Phone}</p>
              <p>
                Business Registration Year: {org.Business_Registration_Year}
              </p>
              <p>Business Legal Status: {org.Business_Legal_Status}</p>
              <p>Registration Number: {org.Registration_Number}</p>
              <p>Tax Identification Number: {org.Tax_Identification_Number}</p>
              <p>
                Business Activity Primary Sectors:{" "}
                {org.Business_Activity_Primary_Sectors}
              </p>
              <p>Employees Number: {org.Employees_Number}</p>
            </div>
          ))}
        </div>

        <div className="pdf-forms">
          {budget.map((item) => (
            <div key={item.id}>
              <p>Devices/Tools: {item.devices_Tools}</p>
              <p>Quantity: {item.quantity}</p>
              <p>Price per Unit: {item.price_per_unit}</p>
              <p>Total Price: {item.total_price}</p>
              <p>Personal Contribution: {item.personal_contribution}</p>
            </div>
          ))}
        </div>

        <div className="pdf-forms">
          {annual_income.map((item) => (
            <div key={item.id}>
              <p>Year: {item.year}</p>
              <p>Annual Income: {item.annualIncome}</p>
            </div>
          ))}
        </div>

        <div className="pdf-forms">
          {references.map((item) => (
            <div key={item.id}>
              <p>Funding Source Name: {item.fundingSourceName}</p>
              <p>Contact Person: {item.contactPerson}</p>
              <p>Grant Project Name: {item.grantProjectName}</p>
              <p>Brief Project Description: {item.brief_Project_Description}</p>
              <p>
                Project Implementation Period:{" "}
                {item.project_Implementation_Period}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ApplicationPdfSecond;
