import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Footer from "../../components/footer/Footer";

export default function Reporting() {
  const breadcrumbItems = [
    { label: "Grants Managment Platform", url: "/" },
    { label: "Reporting" },
  ];
  return (
    <>
      <Navbar />
      <div className="top-container">
        <Breadcrumbs items={breadcrumbItems} />
        {""}
        <div className="container">
          <div className="eligibility-container">
            <div className="eligibility-title">
              <h1>Reporting Requirements</h1>
            </div>
            <div className="eligibility-title-right">
              <h1 className="eligibility-title-instructions">
                CDF is implementing the USAID funded Kosovo Inclusive Economic
                Engagement (IEE) Activity
              </h1>
              <div className="">
                <p>
                  Community Development Fund (CDF) is a nongovernmental
                  organization with the mission to work on community development
                  and create conditions for integration, equal access and
                  inclusion through expertise and innovation solutions, through
                  supporting local initiatives, as well as capacity building in
                  communities. CDF is implementing the USAID funded Kosovo
                  Inclusive Economic Engagement (IEE) Activity, which is a
                  five-year project aimed at empowering and reviving women- and
                  minority-owned micro, small, and medium enterprises (MMSMEs)
                  negatively impacted by the Covid-19 pandemic. The activity
                  will increase economic opportunities and improve the
                  resiliency of target MMSMEss. Strengthening them will contribute
                  to private sector growth and increased integration into the
                  overall economy in Kosovo.
                </p>
              </div>
            </div>
          </div>
        </div>
        {""}

  

        {""}
        <div className="container">
          <div className="eligibility-container">
            <div className="eligibility-title">
              <h1>Reporting - Templates</h1>
            </div>
            <div className="reporting-templates-right">
              <div className="reporting-templates-document-container">
                <div className="reporting-application-document">
                  <img src="/assets/images/icons/pdf.svg" alt="" />
                  <p>Certifikatat.pdf</p>
                  <p>5.6Mb</p>
                  <p className="download-link">Download</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {""}

       
      </div>

      <Footer />
    </>
  );
}
