import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Button from "../../components/button/Button";

export default function SuccesApplicationPhaseOne() {
  return (
    <>
      <div className="top-container">
        <div className="succesPage-container">
          <div className="container">
            <Navbar />
              <h1>Faza e pare e aplikimit u krye me sukses</h1>
              <p>Vazhdoni ne FAZEN e II</p>
              <Link to="/grants-for-sme-second">
                <Button title="Vazhdo"></Button>
              </Link>
          </div>
        </div>
      </div>
    </>
  );
}
