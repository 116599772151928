import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import AdminNavbar from "../../components/admin/adminNavbar/AdminNavbar";
import Button from "../../components/button/Button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./admin.scss";
import Footer from "../../components/footer/Footer";

const useApplicationsData = () => {
  const user = useSelector((state) => state.auth.user);
  const token = user.access_token;
  const [applicationsData, setApplicationsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://apigrant.bbros.al/api/interest-expression/findAllInterst",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setApplicationsData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  return applicationsData;
};

const Admin = () => {
  const { t } = useTranslation();

  const applicationsData = useApplicationsData();

  const formatData = () => {
    return applicationsData.map((application, index) => ({
      name: `${t("admin.numberOfApplications")} ${index + 1}`,
      Approved: application.isApproved ? 1 : 0,
      Rejected: application.isApproved ? 0 : 1,
    }));
  };

  const formatYAxisTick = (value) => {
    return Math.floor(value);
  };

  return (
    <>
      <AdminNavbar />
      <div className="container">
        <div className="top-container" style={{marginTop:"20px"}}>
          <div>
            <div style={{ width: "100%" }}>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={formatData()}>
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey={`${t("admin.approved")}`} stroke="#8884d8" />
                  <Line type="monotone" dataKey={`${t("admin.rejected")}`} stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
              <Link to="/admin-phase-one">
                <Button title={`${t("admin.eoi")}`} />
              </Link>
            </div>
            <div style={{ width: "100%" }}>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={formatData()}>
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey={`${t("admin.approved")}`} stroke="#8884d8" />
                  <Line type="monotone" dataKey={`${t("admin.rejected")}`} stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
              <Link to="/admin-visit">
                <Button title={`${t("admin.visit")}`}  />
              </Link>
            </div>
            <div style={{ width: "100%" }}>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={formatData()}>
                  <CartesianGrid stroke="#ccc" />
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey={`${t("admin.approved")}`} stroke="#8884d8" />
                  <Line type="monotone" dataKey={`${t("admin.rejected")}`} stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
              <Link to="/admin-phase-second">
                <Button title={`${t("admin.fullGrant")}`} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <Footer/>
    </>
  );
};

export default Admin;
