import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../store/features/authSlice";
import { useTranslation } from "react-i18next";

export default function AdminNavbar() {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleItemClick = (url) => {
    setActiveItem(url);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="navbar-container">
      <div className="main-container">
        <div className="navbar-right-left">
          <div className="navbar-right">
            <Link to="/admin">
              <div className="navbar-logo">
                <img src="/assets/images/logo-grant.svg" alt="logo-grant" />
              </div>
            </Link>
          </div>

          <div className="navbar-left">
            <div className="navbar-profile">
              {isAuthenticated ? (
                <button onClick={handleLogout}>
                  <img src="/assets/images/icons/UserCircle.png" alt="" />
                  Logout {user?.user?.firstName}
                </button>
              ) : (
                <Link to="/login">
                  <button>
                    <img src="/assets/images/icons/UserCircle.png" alt="" />
                    Login
                  </button>
                </Link>
              )}
            </div>
            <div className="select-language">
              <select
                value={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
              >
                <option value="al">AL</option>
                <option value="en">EN</option>
                <option value="sr">SR</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
