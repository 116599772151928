import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Button from "../../components/button/Button";

export default function SuccesApplicationPhaseSecond() {
  return (
    <>
    <div className="top-container">
      <div className="succesPage-container">
        <div className="container">
          <Navbar />
            <h1>Aplikimi juaj eshte kryer me sukses</h1>
            <p>Ju faleminderit</p>
            <Link to="/grants">
              <Button title="Shiko Aplikimet"></Button>
            </Link>
        </div>
      </div>
    </div>
  </>
  )
}
