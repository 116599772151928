import React from "react";
import "./progressCard.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ApprovedCard() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const firstName = user?.user?.firstName;

  return (
    <div className="progress-card-container" style={{ height: "280px" }}>
      <div className="progress-card-info">
        <div className="progress-card-button">
          <div className="progress-card-button-first">
            <button
              className="button-card-open"
              style={{ backgroundColor: "green" }}
            >
              {t("approvedCard.approvedButton")}
            </button>
          </div>
        </div>
        <h3>
          {t("approvedCard.greeting")}
          {firstName},
          <br />
          <br />
          {t("approvedCard.message")}
        </h3>
        <p className="progress-card-details-paragraph">
          {t("approvedCard.progressBarDescription")}
        </p>
        <div className="progress-bar-container">
          <div className="progress-bar-background">
            <div
              className="progress-bar"
              style={{ width: "100%", backgroundColor: "green" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
