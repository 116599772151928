import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './accordion.scss';

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <button onClick={handleToggle}>
        {title}
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="arrow-icon" />
      </button>
      {isOpen && <div className="content">{content}</div>}
    </div>
  );
};

export default Accordion;
