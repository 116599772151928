import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Accordion from "../../components/accordion/Accordion";
import Footer from "../../components/footer/Footer";

export default function GrantWriting() {
  const breadcrumbItems = [
    { label: "Grants Managment Platform", url: "/" },
    { label: "Grant Writing Resources" },
  ];
  return (
    <>
      {" "}
      <Navbar />
      <div className="top-container">
        <Breadcrumbs items={breadcrumbItems} />
        <div className="grant-writing-container">
          <div className="container">
            {""}
            <div className="container">
              <div className="eligibility-container">
                <div className="eligibility-title">
                  <h1>Best practices for grant writing</h1>
                </div>
                <div className="eligibility-title-right">
                  <h1 className="eligibility-title-instructions">
                    Inevery already seen image there is a double betrayal: that
                    of the original image and that of the creative idea. The
                    first image — the one copied and declined in a thousand
                    variants that are always recognizable and tedious until
                    exhaustion — remains a memory; the creative idea no longer
                    emerges, because there is no creation. There is a cautious
                    trust in the already seen. It worked once, then it will work
                    again.
                  </h1>
                  <div className="eligibility-paragraph">
                    <p>
                      Commercially, yes. But it’s no longer photography. It is
                      an image, it is a functional object or an expression of
                      stereotypes. It does not like the anarchist variations of
                      art: it’s just a monument to the security of the already
                      seen.The photographer who puts himself and the subject in
                      a car and does not look at that subject — human, animal or
                      natural — but instead looks at a mental image and
                      reassembles it according to a type already seen does not
                      look at the reality and does not interpret and rewrites it
                      creating a new object in the end.
                    </p>
                    <p>
                      The photographer who puts himself and the subject in a car
                      and does not look at that subject — human, animal or
                      natural — but instead looks at a mental image and
                      reassembles it according to a type already seen does not
                      look at the reality and does not interpret and rewrites it
                      creating a new object in the end. He’s just looking inside
                      his own visual memory.Every photo already seen, every
                      already seen composition of the frame, each element
                      arranged in that frame just like a thousand other times is
                      a betrayal of photography.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {""}

            {""}
            <div className="container">
              <div className="eligibility-container">
                <div className="eligibility-title">
                  <h1>Proposal writing templates</h1>
                </div>
                <div className="eligibility-title-right">
                    <div className="award-application-document">
                      <img src="/assets/images/icons/folderNotch.svg" alt="" />
                      <p className="resources-title">Writing Templates</p>
                      <button className="download-btn">Download</button>
                    </div>
                </div>
              </div>
            </div>
            {""}

            {""}
            <div className="container">
              <div className="eligibility-container">
                <div className="eligibility-title">
                  <h1>Tips for successful grant applications</h1>
                </div>
                <div className="eligibility-title-right">
                  <div className="eligibility-paragraph"></div>
                  <Accordion
                    title="What is IEE"
                    content="Rhoncus sed facilisi sed amet. Tristique commodo sodales."
                  />
                  <Accordion
                    title="Eligibility criteria"
                    content="This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion."
                  />
                  <Accordion
                    title="Instructions and guidelines for applying"
                    content="This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion."
                  />
                  <Accordion
                    title="Notification of award"
                    content="This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion.
              This is the content of the first accordion."
                  />
                </div>
              </div>
            </div>
            {""}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
