import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Footer from "../../components/footer/Footer";
import "./award.scss";
import { useSelector } from "react-redux";

export default function Award() {
  const breadcrumbItems = [
    { label: "Grants Management Platform", url: "/" },
    { label: "Award" },
  ];

  const user = useSelector((state) => state.auth.user);
   

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editedProfile, setEditedProfile] = useState({
    company: user.user.firstName,
    contactPhone: user.user.email,
    companySite: user.user.lastName,
    city: user.user.id,
    communication: user.user.phoneNumber,
  });

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const handleProfileInputChange = (event) => {
    const { name, value } = event.target;
    setEditedProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleEditProfileSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `https://apigrant.bbros.al/api/users/${user.user.id}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedProfile),
        }
      );

      if (response.ok) {
        closeEditModal();
      }
    } catch (error) {}
  };

  return (
    <>
      <Navbar />
      <div className="top-container">
        <Breadcrumbs items={breadcrumbItems} />
        <div className="award-container">
          <div className="container">
            <div className="award-content">
              <div className="award-title">
                <h3>Congratulations</h3>
                <h1>You have won the Grant for MSMEs - 2</h1>
              </div>
              <div className="award-application">
                <h3>Your application’s documents</h3>
                <div className="award-application-document-container">
                  <div className="award-application-document">
                    <img src="/assets/images/icons/pdf.svg" alt="" />
                    <p>Certifikatat.pdf</p>
                    <p>5.6Mb</p>
                    <div className="award-application-document-calendar">
                      <img
                        src="/assets/images/icons/calendarBlank.svg"
                        alt=""
                      />
                      <p className="award-calendar-paragraph">
                        19/05/2023, 19:40
                      </p>
                    </div>
                  </div>
                  <div className="award-application-document">
                    <img src="/assets/images/icons/pdf.svg" alt="" />
                    <p>Numri Biznesit.pdf</p>
                    <p>5.6Mb</p>
                    <div className="award-application-document-calendar">
                      <img
                        src="/assets/images/icons/calendarBlank.svg"
                        alt=""
                      />
                      <p className="award-calendar-paragraph">
                        19/05/2023, 19:40
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="eligibility-container">
                  <div className="eligibility-title">
                    <h1>Payment disbursement process</h1>
                  </div>
                  <div className="eligibility-title-right">
                    <h1 className="eligibility-title-instructions">
                      Inevery already seen image there is a double betrayal:
                      that of the original image and that of the creative idea.
                      The first image — the one copied and declined in a
                      thousand variants that are always recognizable and tedious
                      until exhaustion — remains a memory; the creative idea no
                      longer emerges, because there is no creation. There is a
                      cautious trust in the already seen. It worked once, then
                      it will work again.
                    </h1>
                    <div className="eligibility-paragraph">
                      <p>
                        Commercially, yes. But it’s no longer photography. It is
                        an image, it is a functional object or an expression of
                        stereotypes. It does not like the anarchist variations
                        of art: it’s just a monument to the security of the
                        already seen.The photographer who puts himself and the
                        subject in a car and does not look at that subject —
                        human, animal or natural — but instead looks at a mental
                        image and reassembles it according to a type already
                        seen does not look at the reality and does not interpret
                        and rewrites it creating a new object in the end.
                      </p>
                      <p>
                        The photographer who puts himself and the subject in a
                        car and does not look at that subject — human, animal or
                        natural — but instead looks at a mental image and
                        reassembles it according to a type already seen does not
                        look at the reality and does not interpret and rewrites
                        it creating a new object in the end. He’s just looking
                        inside his own visual memory.Every photo already seen,
                        every already seen composition of the frame, each
                        element arranged in that frame just like a thousand
                        other times is a betrayal of photography.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="eligibility-container">
                  <div className="eligibility-title">
                    <h1>Grantee profile</h1>
                  </div>
                  <div className="award-profile-right">
                    <div className="award-profile-title">
                      <p>Profile Details</p>
                      <p className="award-edit" onClick={openEditModal}>
                        Edit Profile
                      </p>
                    </div>
                    <div className="award-profile-info">
                      <ul className="award-profile-info-list">
                        <li>Company</li>
                        <li>Contact Phone</li>
                        <li>Company Site</li>
                        <li>City</li>
                        <li>Communication</li>
                      </ul>
                      <ul className="award-profile-info-second-list">
                        <li>{editedProfile.company}</li>
                        <li>{editedProfile.contactPhone}</li>
                        <li>{editedProfile.companySite}</li>
                        <li>{editedProfile.city}</li>
                        <li>{editedProfile.communication}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="edit-modal">
          <div className="edit-modal-content">
            <h2>Edit Profile</h2>
            <form onSubmit={handleEditProfileSubmit}>
              <input
                type="text"
                name="company"
                value={editedProfile.company}
                onChange={handleProfileInputChange}
              />
              <input
                type="text"
                name="contactPhone"
                value={editedProfile.contactPhone}
                onChange={handleProfileInputChange}
              />
              <input
                type="text"
                name="companySite"
                value={editedProfile.companySite}
                onChange={handleProfileInputChange}
              />
              <input
                type="text"
                name="city"
                value={editedProfile.city}
                onChange={handleProfileInputChange}
              />
              <input
                type="text"
                name="communication"
                value={editedProfile.communication}
                onChange={handleProfileInputChange}
              />
              <button type="submit">Save</button>
              <button type="button" onClick={closeEditModal}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}
