import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Modal from "./modal/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplicationPdf from "../../../pages/grantsForSme/ApplicationPdf";
import { savePDF } from "@progress/kendo-react-pdf";
import AdminNavbar from "../adminNavbar/AdminNavbar";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { downloadExcel } from "react-export-table-to-excel";
import Footer from "../../../components/footer/Footer";

const ApproveModal = ({ isOpen, onClose, onConfirm, applicationId, content, setContent }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    onConfirm={() => onConfirm(applicationId, 1)}
    actionType="approve"
    content={content}
    setContent={setContent}
  />
);

const RejectModal = ({ isOpen, onClose, onReject, applicationId, content, setContent }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    onReject={() => onReject(applicationId, 0)}
    actionType="reject"
    content={content}
    setContent={setContent}
  />
);

export default function AdminVisitList() {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    const token = user?.access_token;
    fetch("https://apigrant.bbros.al/api/visit/findAllVisits", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const updatedApplications = data.map((app) => ({
          ...app,
          isVisitApproved: app?.isVisitApproved,
        }));
        setApplications(updatedApplications);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [user?.access_token]);

  const handleApprove = (applicationId, isVisitApproved) => {
    const token = user?.access_token;
    fetch(
      `https://apigrant.bbros.al/api/visit/approve/${applicationId}/${isVisitApproved}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isVisitApproved, content }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setApplications((prevApplications) =>
          prevApplications.map((app) => {
            if (app.id === applicationId) {
              return { ...app, isVisitApproved };
            }
            return app;
          })
        );
        if (isVisitApproved === 1) {
          toast.success("Application approved successfully!");
        } else if (isVisitApproved === 0) {
          toast.success("Application rejected successfully!");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };

  const closeModal = () => {
    setSelectedApplication(null);
    setIsApproveModalOpen(false);
    setIsRejectModalOpen(false);
  };

  const handleApprovalConfirmation = (action, applicationId) => {
    if (action === "approve") {
      setIsApproveModalOpen(true);
      setSelectedApplication({ id: applicationId });
    } else if (action === "reject") {
      setIsRejectModalOpen(true);
      setSelectedApplication({ id: applicationId });
    }
  };

  return (
    <>
      <div className="applicant-list-container">
        <AdminNavbar />
        <div className="main-container">
          <div className="top-container">
            <div>
              <Link to="/admin">
                <div className="applicant-list-arrow">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                  <p>Kthehu mrapa</p>
                </div>
              </Link>
              <h1>Aplikuesit ne fazen e Vizites ne Terren</h1>
            </div>
            {isLoading ? (
              <div className="loader">
                <BeatLoader color="red" loading={isLoading} />
              </div>
            ) : (
              <table className="applicant-table" ref={tableRef}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name and Surname</th>
                    <th>Business Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {applications.map((app) => (
                    <tr key={app.id}>
                      <td>{`#${app.id}`}</td>
                      <td>{app?.interest?.legal_Representative_fullName}</td>
                      <td>{app?.interest?.businessName}</td>
                      <td>{app?.interest?.email}</td>
                      <td>{app?.interest?.phoneNumber}</td>
                      <td>
                        {app?.isVisitApproved === 1 ? (
                          <p style={{ color: "green" }}>Approved</p>
                        ) : app?.isVisitApproved === 0 ? (
                          <p style={{ color: "red" }}>Rejected</p>
                        ) : (
                          <p style={{ color: "orange" }}>In review</p>
                        )}
                      </td>
                      <td className="applicant-button">
                        <select
                          onChange={(e) =>
                            handleApprovalConfirmation(e.target.value, app.id)
                          }
                        >
                          <option value="none">Select an action</option>
                          <option value="approve">
                            Approve for Full Grant Application
                          </option>
                          <option value="reject">Reject</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {isApproveModalOpen && (
        <ApproveModal
          isOpen={isApproveModalOpen}
          onClose={closeModal}
          onConfirm={handleApprove}
          applicationId={selectedApplication?.id}
          content={content}
          setContent={setContent}
        />
      )}
      {isRejectModalOpen && (
        <RejectModal
          isOpen={isRejectModalOpen}
          onClose={closeModal}
          onReject={handleApprove}
          applicationId={selectedApplication?.id}
          content={content}
          setContent={setContent}
        />
      )}
      <Footer />
    </>
  );
}
