import React, { useRef, useState } from "react";
import "./verifyCodePage.scss";

function VerifyCodePage() {
  const [code, setCode] = useState(Array(4).fill(""));
  const inputsRef = useRef([]);

  const handleChange = (index, value) => {
    if (/^[0-9]$/.test(value) || value === "") {
      let newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (index < 3 && value !== "") {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);
    if (/^\d{1,4}$/.test(pastedData)) {
      setCode(pastedData.split(""));
    }
  };

  const handleSubmit = () => {
  };

  return (
    <div className="reset-password-container">
      <div className="title">Verify Code</div>
      <div className="code-input-container">
        {Array(4)
          .fill()
          .map((_, index) => (
            <input
              type="text"
              value={code[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              onPaste={handlePaste}
              maxLength="1"
              ref={(el) => (inputsRef.current[index] = el)}
              key={index}
            />
          ))}
      </div>
      <button onClick={handleSubmit} disabled={code.includes("")}>
        Submit
      </button>
    </div>
  );
}

export default VerifyCodePage;
