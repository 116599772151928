import React, { useState, useEffect, useRef } from "react";
import "./adminList.scss";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { savePDF } from "@progress/kendo-react-pdf";
import AdminNavbar from "../adminNavbar/AdminNavbar";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { downloadExcel } from "react-export-table-to-excel";
import JSZip from "jszip";
import FileSaver from "file-saver";
import ApplicationPdfSecond from "../../../pages/grantsForSme/ApplicationPdfSecond";
import Footer from "../../footer/Footer";

export default function AdminListPhaseTwo() {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState("");
  const tableRef = useRef(null);
  const componentRef = useRef();
  const handleDownloadPdf = (application) => {
    const applicaitionId = application?.applicaitionId;
    setSelectedApplication({ ...application, applicaitionId });
  };

  useEffect(() => {
    if (selectedApplication) {
      savePDF(componentRef.current, {
        title: "Pdf Application",
        fileName: `PDF APPLICATION`,
        paperSize: "A4",
      });
    }
  }, [selectedApplication]);

  const downloadFilesAsZip = async (applicationId) => {
    try {
      const response = await fetch(
        `https://apigrant.bbros.al/api/files/${applicationId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch files.");
      }
      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error("Invalid response: Files data is not an array.");
      }
      if (data.length === 0) {
        throw new Error("No files found for the application.");
      }
      const zip = new JSZip();
      await Promise.all(
        data.map(async (file) => {
          const response = await fetch(file.path);
          const fileData = await response.blob();
          zip.file(file.filename, fileData);
        })
      );
      const zipFile = await zip.generateAsync({ type: "blob" });
      FileSaver.saveAs(zipFile, "download.zip");
    } catch (error) {
      console.error("Error downloading files:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const token = user.access_token;

    fetch("https://apigrant.bbros.al/api/application/findAllApplications", {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const updatedApplications = data.map((app) => ({
          ...app,
          isApprove: false,
        }));
        setApplications(updatedApplications);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("API request error:", error);
        setIsLoading(false);
      });
  }, [user.access_token]);

  const handleApprove = (applicationId, isApprove) => {
    const token = user.access_token;
    fetch(
      `https://apigrant.bbros.al/api/application/approveApplication/${applicationId}/${isApprove}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isApprove, content: "test content" }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to approve application.");
        }
      })
      .then((data) => {
        console.log("Application approval response:", data);
        setApplications((prevApplications) =>
          prevApplications.map((app) => {
            if (app.id === applicationId) {
              return {
                ...app,
                isApprove: !app.isApprove,
              };
            }
            return app;
          })
        );
        const app = applications.find((app) => app.id === applicationId);
        if (app.isApprove) {
          toast.success("Application unapproved successfully!");
        } else {
          toast.success("Application approved successfully!");
        }
      })
      .catch((error) => {
        console.log("API request error:", error);
        toast.error(error.message);
      });
  };

  const handleApprovalConfirmation = (action, applicationId) => {
    if (action === "approve") {
      handleApprove(applicationId, 1);
    } else if (action === "reject") {
      handleApprove(applicationId, 0);
    } else if (action === "view") {
      const selectedApp = applications.find((app) => app.id === applicationId);
      handleDownloadPdf(selectedApp);
    }
  };

  const handleDownloadExcel = () => {
    const tableData = applications.map((app) => ({
      "#": `#${app?.id}`,
      "Name of bussiness": app?.interestExpression?.businessName,
      "Business owner": app?.interestExpression?.businessOwners,
      "Business Size": app?.interestExpression?.employeeNumber,
      "Business sector": app?.interestExpression?.businessDescription,
      Municipality: "Prishtina",
      Ethnicity: app?.interestExpression?.ethnicAffiliation,
      Gender: app?.genderMinorityCommunityAspects,
      Age: "30",
      "Employees Full time": app?.interestExpression?.employeeNumber,
      "Employess Seasonal": app?.interestExpression?.seasonalWorkers,
      "Sales 2020, 2021, 2022": app?.sales_data,
    }));

    downloadExcel({
      fileName: "Excel Application Table.xls",
      sheet: "Users",
      tablePayload: {
        header: [
          "#",
          "Name of bussiness",
          "Business owners",
          "Business Size",
          "Business sector",
          "Municipality",
          "Ethnicity",
          "Gender",
          "Age",
          "Employees Full time",
          "Employess Seasonal",
          "Sales 2020, 2021, 2022",
        ],
        body: tableData,
      },
    });
  };

  return (
    <div className="applicant-list-container">
      <AdminNavbar />
      <div className="container">
        <div className="top-container">
          <div>
            <>
              <Link to="/admin">
                <div className="applicant-list-arrow">
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                  <p>Kthehu mrapa</p>
                </div>
              </Link>
            </>
            <h1>Aplikuesit ne fazen e Aplikimit te Plote per Grant</h1>
            <div className="export-list">
              <button onClick={handleDownloadExcel}>Export Excel</button>
            </div>
          </div>
          {isLoading ? (
            <div className="loader">
              <BeatLoader color="red" loading={isLoading} />
            </div>
          ) : (
            <>
              <table className="applicant-table" ref={tableRef}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name and Surname</th>
                    <th>Business Name</th>
                    <th>E-mail</th>
                    <th>Phone Number</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {applications.map((app) => (
                    <tr key={app.id}>
                      <td>{`#${app.id}`}</td>
                      <td>
                        {app?.interestExpression?.legal_Representative_fullName}
                      </td>
                      <td>{app?.interestExpression?.businessName}</td>
                      <td>{app?.interestExpression?.email}</td>
                      <td>{app?.interestExpression?.phoneNumber}</td>
                      <td>
                        {app?.isApproved === true ? (
                          <p style={{ color: "green" }}>Approved</p>
                        ) : app?.isApproved === false ? (
                          <p style={{ color: "red" }}>Rejected</p>
                        ) : (
                          <p style={{ color: "orange" }}>In review</p>
                        )}
                      </td>
                      <td className="applicant-button">
                        <select
                          onChange={(e) =>
                            handleApprovalConfirmation(e.target.value, app.id)
                          }
                        >
                          <option value="none">Select an action</option>
                          <option value="approve">Approve for Funding</option>
                          <option value="reject">Reject</option>
                        </select>
                        <button
                          onClick={() =>
                            handleApprovalConfirmation("view", app.id)
                          }
                          style={{
                            padding: "5px",
                            backgroundColor: "#8888884a",
                            color: "white",
                          }}
                        >
                          View Application
                        </button>
                        <button
                          style={{
                            padding: "5px",
                            backgroundColor: "#8888884a",
                            color: "white",
                          }}
                          onClick={downloadFilesAsZip}
                        >
                          Download Zip
                        </button>
                      </td>
                    </tr>
                  ))}
                  {!isLoading && applications.length === 0 && (
                    <tr>
                      <td colSpan={7}>No data to show!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <div style={{ height: 0, overflow: "hidden" }}>
        {selectedApplication && (
          <ApplicationPdfSecond
            reff={componentRef}
            application={selectedApplication}
            applicationId={selectedApplication?.applicationId}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}
