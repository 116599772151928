import React from "react";
import "./openCard.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OpenCard({ data }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="card-container" style={{ height: "165px" }}>
        <div className="card-info">
          <div className="card-button">
            <button>{t('openCard.openCallsButton')}</button>
          </div>
          <h3>{t('openCard.grantForWomenAndMinorities')}</h3>
          <p style={{ fontSize: "15px" }}>
            {t('openCard.startYourApplication')}
          </p>
        </div>
        <Link to="/grants-for-sme">
          <div className="grant-apply-button">
            <button>{data ? t('openCard.continueButton') : t('openCard.applyNowButton')}</button>
          </div>
        </Link>
      </div>
    </>
  );
}
