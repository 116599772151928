import React from "react";
import "./button.scss";

const Button = ({ onClick, title, disabled, type }) => {
  return (
    <button className="red-button" onClick={onClick} disabled={disabled} type={type}>
      {title}
    </button>
  );
};

export default Button;
