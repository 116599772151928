import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/axiosInstance";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";


export const createInterest = createAsyncThunk(
    "createInterest/createInterest",
    async (requestData) => {
      try {
        const response = await api.post(
          "/interest-expression/createInterest",
          requestData
        );
        toast.success("Form submitted successfully!");
        return response.data[0].id;
      } catch (error) {
        toast.error("An error occurred while submitting the form.");
        throw error;
      }
    }
  );
  
  export default createInterest;
  
