import React, { useRef } from "react";
import "./progressCard.scss";
import { savePDF } from "@progress/kendo-react-pdf";
import ApplicationPdf from "../../../pages/grantsForSme/ApplicationPdf";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function PhaseSecondCard() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const firstName = user?.user?.firstName;

  return (
    <div className="progress-card-container">
      <div className="progress-card-info">
        <div className="progress-card-button">
          <div className="progress-card-button-first">
            <button
              className="button-card-open"
              style={{ backgroundColor: "green" }}
            >
              {t('phaseSecondCard.buttonLabel')}
            </button>
          </div>
        </div>
        <h3>
        { firstName }
          {t('phaseSecondCard.greeting')}
        </h3>
        <p className="progress-card-details-paragraph">
          {t('phaseSecondCard.progressBarDescription')}
        </p>
        <div className="progress-bar-container">
          <div className="progress-bar-background">
            <div className="progress-bar" style={{ width: "50%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
